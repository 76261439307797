import {
  Button,
  Modal,
  Paper,
  Typography,
  Box,
  Stack,
  CircularProgress,
} from "@mui/material"
import { useState } from "react"
import { deleteLocation } from "../api"

export default function DeleteModal(props) {
  const [loading, setLoading] = useState(false)
  const action = () => {
    setLoading(true)
    deleteLocation(props.data?.id)
      .then(() => {
        props.toast("Location deleted successfully!", "success")
        props.refresh()
        props.onClose()
      })
      .catch((err) => {
        console.log(err)
        props.toast(err?.data?.message || "Something went wrong")
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal {...props}>
      <Box
        className="row"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          className="column"
          elevation={0}
          sx={{
            p: "1rem",
            width: "30rem",
          }}
        >
          <Typography>
            Are you sure you want to delete location {props.data?.name}?
          </Typography>
          <Stack direction="row" justifyContent="space-between" marginTop={2}>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={action} variant="contained">
              {loading ? (
                <CircularProgress size={"1.4rem"} color="inherit" />
              ) : (
                "Delete"
              )}
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Modal>
  )
}
