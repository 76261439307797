import { Menu, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useState } from "react"
import Calendar2 from "./calendar"
import styles from "../style/styleDateSelect"
import { useSelector } from "react-redux"
import CalendarMonthRoundedIcon from '../assets_icons/Calendar.svg';

const useStyles = makeStyles(styles)

function DateSelect({}) {
  const classes = useStyles()
  const dates = useSelector((state) => state.dateRange)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <div>
      <Button sx={{ color: 'black' }} onClick={handleClick}>
        <div className={classes.icons}>
          <img src={CalendarMonthRoundedIcon} />
        </div>
        {dates[0].from} - {dates[0].to}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Calendar2 type='nav' handleClose={handleClose} />
      </Menu>
    </div>
  );
}

export default DateSelect
