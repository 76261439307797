import { styled } from "@mui/material/styles"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { useNavigate } from "react-router-dom"

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: "#fff",
  "& .MuiToggleButtonGroup-grouped": {
    "&:hover": {
      background: "transparent",
    },
    textTransform: "none",
    margin: 0,
    border: 0,

    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: "#BDBDBD",
  "&.Mui-selected": {
    color: "#fff",
    background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
    "&:hover": {
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    },
  },
}))

export const Toggle = ({
  val1,
  val2,
  label1,
  label2,
  selected,
  setSelected,
  link1,
  link2,
}) => {
  const navigate = useNavigate()
  const handleSelect = (_, newSelection) => {
    if (newSelection === null) return
    setSelected(newSelection)
    if (link1 && newSelection === val1) navigate(link1)
    if (link2 && newSelection === val2) navigate(link2)
  }

  return (
    <StyledToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleSelect}
      color="primary"
    >
      <StyledToggleButton value={val1}>{label1}</StyledToggleButton>
      <StyledToggleButton value={val2}>{label2}</StyledToggleButton>
    </StyledToggleButtonGroup>
  )
}

export const Toggle2 = ({ options, selected, setSelected }) => {
  const handleSelect = (_, newSelection) => setSelected(newSelection)
  return (
    <StyledToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleSelect}
      color="primary"
    >
      {options.map(({ label, val }) => (
        <StyledToggleButton value={val}>{label}</StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}
