const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JULY', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'];
const weeks = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const formatToTwoDigits = (str) => {
  if (str.length === 1) str = '0' + str;
  return str;
};

export const getAllHoursInRange = () => {
  return [
    '12 am',
    '1 am',
    '2 am',
    '3 am',
    '4 am',
    '5 am',
    '6 am',
    '7 am',
    '8 am',
    '9 am',
    '10 am',
    '11 am',
    '12 pm',
    '1 pm',
    '2 pm',
    '3 pm',
    '4 pm',
    '5 pm',
    '6 pm',
    '7 pm',
    '8 pm',
    '9 pm',
    '10 pm',
    '11 pm',
  ];
};

const getWeekIndex = (date) => {
  let day = new Date(date).getDay();
  if (day === 0) return weeks[6];
  return weeks[day - 1];
};

export const getAllWeekDaysInRange = (startDate, endDate) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  let arr = [];
  let currDate = startDate;
  while (currDate <= endDate && arr.length <= 7) {
    arr.push(getWeekIndex(currDate));
    currDate.setDate(currDate.getDate() + 1);
  }
  return arr;
};

export const getWeekFromDate = (date) => {
  let currentdate = new Date(date);
  let oneJan = new Date(currentdate.getFullYear(), 0, 1);
  let numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  return Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
};

export const getAllWeeksInRange = (startDate, endDate) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  if (startDate.getFullYear() > endDate.getFullYear()) return [];
  if (startDate.getFullYear() === endDate.getFullYear()) {
    let startNum = getWeekFromDate(startDate) - 1;
    let endNum = getWeekFromDate(endDate) + 1;
    let arr = [];
    for (let i = startNum; i <= endNum; i++) arr.push(`W${i} - ${startDate.getFullYear()}`);
    return arr;
  }
  // if start and end have different years
  let arr = [];
  while (startDate.getFullYear() < endDate.getFullYear()) {
    let startNum = getWeekFromDate(startDate);
    let endNum = getWeekFromDate(new Date(startDate.getFullYear(), 11, 31));
    for (let i = startNum; i <= endNum; i++) arr.push(`W${i} - ${startDate.getFullYear()}`);
    startDate.setFullYear(startDate.getFullYear() + 1);
    startDate.setMonth(0);
    startDate.setDate(1);
  }
  if (startDate.getFullYear() === endDate.getFullYear()) {
    let startNum = getWeekFromDate(startDate);
    let endNum = getWeekFromDate(endDate);
    for (let i = startNum; i <= endNum; i++) arr.push(`W${i} - ${startDate.getFullYear()}`);
  }
  return arr;
};

export const getAllMonthsInRange = (startDate, endDate) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  let arr = [];
  let currDate = startDate;
  while (currDate <= endDate && arr.length <= 12) {
    arr.push(months[currDate.getMonth()]);
    currDate.setMonth(currDate.getMonth() + 1);
  }
  return arr;
};

export const getAllDaysInRange = function (start, end) {
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr.map((item) => {
    const date = formatToTwoDigits(item.getDate().toString());
    const month = formatToTwoDigits((item.getMonth() + 1).toString());
    const year = formatToTwoDigits(item.getFullYear().toString());
    return month + '-' + date + '-' + year.slice(2);
  });
};

export const sortByMonthHelper = (a, b) => months.indexOf(a.name) - months.indexOf(b.name);

export const sortByWeekDayHelper = (a, b) => weeks.indexOf(a.name) - weeks.indexOf(b.name);
