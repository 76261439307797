import { createTheme } from "@mui/material"

let theme = createTheme({
  shape: {
    borderRadius: 15,
  },
  typography: {
    fontSize: 16,
    fontFamily: "'Inter',sans-serif",
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#449A68",
      light: "#9FE2BE",
      dark: "#9FF5BE",
      contrastText: "#fff",
    },
  },
})

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: "50px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          background: "#fff",
          paddingLeft: "14px",
          paddingRight: "14px",
        },
        contained: {
          background: `linear-gradient(107.56deg, ${theme.palette.primary.light} 3.15%, ${theme.palette.primary.main} 95.32%)`,
          "&:hover": {
            background: `linear-gradient(107.56deg, ${theme.palette.primary.main} 3.15%, ${theme.palette.primary.dark} 95.32%)`,
          },
        },
      },
    },
  },
})

export default theme
