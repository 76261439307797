import { createSlice } from '@reduxjs/toolkit';

const date = new Date();
let startMonth = date.getMonth() + 1;
let todayDate = date.getDate();

if (todayDate < 10) {
  todayDate = '0' + todayDate;
}
if (startMonth < 10) {
  startMonth = '0' + startMonth;
}
let endMonth = startMonth;

const from = date.getFullYear() + '-' + startMonth + '-' + todayDate;
const to = date.getFullYear() + '-' + endMonth + '-' + todayDate;

const selectedDateSlice = createSlice({
  name: 'presentDate',
  initialState: {
    from: from,
    to: to,
    id: 1,
  },
  reducers: {
    addSelectedDate: (state, action) => {
      state.id = action.payload.id;
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
    updateSelectedDate: (state, action) => {
      state.id = action.payload.id;
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
    removeDate: (state, action) => {
      state.id = null;
      state.from = null;
      state.to = null;
    }
  },
});

export const { addSelectedDate, updateSelectedDate, removeDate } = selectedDateSlice.actions;
export default selectedDateSlice.reducer;
