const config = {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_kowmbkxv7',
    APP_CLIENT_ID: '79gl9phr9fe06fctgettq64aib',
  },
  baseApiURL: 'https://o3kr9llhq1.execute-api.us-east-1.amazonaws.com/development/api',
  webSocketURL: 'wss://boch46ret2.execute-api.us-east-1.amazonaws.com/development',
};
export default config;
