// import { Check, Close } from "@mui/icons-material";
import { useState } from "react"
import {
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
// import { MailIcon, NotificationIcon, PersonIcon, PhoneIcon, WorkMatIcon } from '../../assets/Icons/Icons';
import { IconInput } from "./IconInput"

export const ProfileEdit = ({ saveProfile, profile, cancel }) => {
  const [name, setName] = useState(profile.name)
  const [nameError, setNameError] = useState(false)
  const [phone, setPhone] = useState(profile.phone)
  const [phoneError, setPhoneError] = useState(false)
  const [email, setEmail] = useState(profile.email)
  const [emailError, setEmailError] = useState(false)
  const [designation, setDesignation] = useState(profile.designation)
  const [designationError, setDesignationError] = useState(false)
  const [notif, setNotif] = useState(
    profile.pushNotifications && profile.emailNotifications
      ? 0
      : profile.pushNotifications
      ? 1
      : 2
  )
  const [loading, setLoading] = useState(false)

  const sx = {
    my: "0rem",
    "& .MuiOutlinedInput-root": {
      height: "5rem",
      input: {
        padding: "1.1rem",
      },
    },
  }

  const onSubmit = async () => {}

  return (
    <>
      <Paper
        elevation={0}
        style={{
          width: "100%",
          //margin: "10rem auto 0 auto",
          marginTop: "2rem",
          padding: "3rem 4rem",
        }}
      >
        <div
          className="column w-100"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography sx={{ mb: "3rem" }} variant="h1" color="primary">
            {profile.name}
          </Typography>

          <IconInput
            error={nameError}
            helperText={nameError ? "Enter a valid Name" : " "}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            fullWidth
            sx={sx}
            placeholder="Name"
            // Icon={PersonIcon}
          />
          <IconInput
            error={phoneError}
            helperText={phoneError ? "Enter a valid mobile number" : " "}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value)
            }}
            fullWidth
            sx={sx}
            type="tel"
            placeholder="Phone Number"
            // Icon={PhoneIcon}
          />

          <IconInput
            error={emailError}
            helperText={emailError ? "Enter a valid email id" : " "}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            fullWidth
            sx={sx}
            type="email"
            placeholder="Email"
            // Icon={MailIcon}
          />

          <IconInput
            error={designationError}
            helperText={designationError ? "Enter a valid designation" : " "}
            value={designation}
            onChange={(e) => {
              setDesignation(e.target.value)
            }}
            fullWidth
            sx={sx}
            type="text"
            placeholder="Designation"
            // Icon={WorkMatIcon}
          />

          <TextField
            value={notif}
            variant="outlined"
            sx={sx}
            select
            helperText=" "
            fullWidth
            onChange={(e) => {
              setNotif(e.target.value)
            }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position='start'>
            //       <NotificationIcon color='secondary' />
            //     </InputAdornment>
            //   ),
            // }}
            placeholder="Notification preference"
          >
            <MenuItem value={0}>Push and Email Notifications</MenuItem>

            <MenuItem value={1}>Push Notifications</MenuItem>

            <MenuItem value={2}>Email Notifications</MenuItem>
          </TextField>
        </div>
      </Paper>
      <div
        className="row w-100"
        style={{ marginTop: "2rem", justifyContent: "space-between" }}
      >
        <Button
          onClick={cancel}
          color="inherit"
          variant="outlined"
          style={{ width: "35%" }}
        >
          {/* <Close fontSize='small' sx={{ mr: '0.5rem' }} /> */}
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          style={{ width: "40%" }}
        >
          {/* <Check sx={{ mr: '0.5rem' }} fontSize='small' /> */}
          Save Changes
        </Button>
      </div>
    </>
  )
}
