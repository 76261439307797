import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Button, Stack, Typography, CircularProgress } from "@mui/material"
import AddLocation from "../layouts/add-location"
import { Link } from "react-router-dom"
import { getAllStores, getEntityById, updateEntity } from "../api"
import { useContext, useState } from "react"
import UserContext from "../contexts/user-context"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { addLocations } from "../redux/stores"
import { withToast } from "../components/withToast"
import { useEffect } from "react"
import { ClockIcon } from "../assets_icons/Icons"
import { IconInput } from "../components/IconInput"
import DeleteModal from "../components/DeleteModal"

const Location = ({ toast }) => {
  const [loading0, setLoading0] = useState(false)
  const [entityId, setEntityId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [stepTwo, setStepTwo] = useState(true)
  const [entity, setEntity] = useState(null)
  const { locations } = useSelector((state) => state.stores)
  const { user } = useContext(UserContext)
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)

  const [showAddLocation, setShowAddLocation] = useState(false)

  const openDeleteModal = (location) => {
    setSelectedLocation(location)
    setDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setSelectedLocation(null)
    setDeleteModal(false)
  }

  const openEditModal = (location) => {
    setSelectedLocation(location)
    setShowAddLocation(true)
  }

  const closeAddLocation = () => {
    setSelectedLocation(null)
    setShowAddLocation(false)
  }

  const iconProps = {
    fontSize: "large",
    sx: {
      color: "#449A68",
    },
  }

  const refresh = () => {
    getAllStores(user.sub).then((res) => {
      if (res.data?.length)
        dispatch(
          addLocations({
            locations: res.data,
          })
        )
    })
  }

  const submitStepTwo = () => {
    if (entity?.opening && entity?.closing) {
      setLoading(true)
      updateEntity(entityId, entity)
        .then(() => {
          toast("Times updated successfully!", "success")
          setStepTwo(false)
        })
        .catch((err) => {
          toast(err?.data.message || "Something went wrong")
        })
        .finally(() => setLoading(false))
    } else toast("Please set both times first")
  }

  const checkStepTwo = () => {
    setLoading0(true)
    getEntityById(user.sub).then(({ data }) => {
      const { id, ...rest } = data
      setEntityId(id)
      console.log(rest)
      setEntity(rest)
      if (data?.opening && data?.closing) {
        setStepTwo(false)
      }
      setLoading0(false)
    })
  }

  useEffect(() => {
    checkStepTwo()
  }, [])

  return (
    <div style={{ padding: "2rem 10rem 0 10rem" }}>
      {stepTwo ? (
        <div style={{ width: "50%", margin: "0 auto" }}>
          <Typography color="primary" variant="h6" sx={{ textAlign: "center" }}>
            {loading0 ? "Loading..." : "Set default opening and closing times"}
          </Typography>
          {!loading0 && (
            <>
              <div
                style={{
                  dsiplay: "flex",
                  flexDirection: "column",
                  marginTop: "2rem",
                }}
              >
                <label style={{ marginBottom: "5px", display: "block" }}>
                  Opening Time
                </label>
                <IconInput
                  value={entity?.opening || ""}
                  onChange={(e) =>
                    setEntity({
                      ...entity,
                      opening: e.target.value,
                    })
                  }
                  required
                  type="time"
                  placeholder="Opening Time"
                  Icon={<ClockIcon {...iconProps} />}
                  fullWidth
                />
                <label style={{ marginBottom: "5px", display: "block" }}>
                  Closing Time
                </label>
                <IconInput
                  value={entity?.closing || ""}
                  onChange={(e) =>
                    setEntity({
                      ...entity,
                      closing: e.target.value,
                    })
                  }
                  required
                  type="time"
                  placeholder="Closing Time"
                  Icon={<ClockIcon {...iconProps} />}
                  fullWidth
                />
              </div>

              <div className="container-column" style={{ marginTop: "0rem" }}>
                <Button
                  onClick={submitStepTwo}
                  style={{
                    fontWeight: 700,
                    fontSize: 20,
                    width: "100%",
                    marginTop: "1rem",
                  }}
                  color="primary"
                  variant="contained"
                >
                  {loading ? (
                    <CircularProgress size={"1.4rem"} color="inherit" />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <AddLocation
            open={showAddLocation}
            onClose={closeAddLocation}
            refresh={refresh}
            toast={toast}
            opening={entity?.opening}
            closing={entity?.closing}
            timezone={entity?.timezone}
            data={selectedLocation}
          />
          <Typography
            color="primary"
            variant="h6"
            sx={{ textAlign: "center" }}
            marginBottom={2}
          >
            Add locations of stores
          </Typography>
          {locations?.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locations.map((row) => (
                    <TableRow
                      key={row.code}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.code}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>
                        <Button onClick={() => openEditModal(row)}>Edit</Button>
                        <Button
                          onClick={() => openDeleteModal(row)}
                          sx={{ color: "red" }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Typography
                sx={{ color: "gray", textAlign: "center", marginTop: 2 }}
              >
                No locations found.
              </Typography>
              <Typography
                sx={{ color: "gray", textAlign: "center", marginBottom: 3 }}
              >
                Please add a location.
              </Typography>
            </>
          )}

          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            marginTop={2}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowAddLocation(true)}
            >
              Add Location
            </Button>
            {!!locations?.length && (
              <Link to="/">
                <Button>Continue</Button>
              </Link>
            )}
          </Stack>
          <DeleteModal
            open={deleteModal}
            onClose={closeDeleteModal}
            data={selectedLocation}
            refresh={refresh}
            toast={toast}
          />
        </>
      )}
    </div>
  )
}

export default withToast(Location)
