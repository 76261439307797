import { createTheme } from "@mui/material"

const theme2 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      b1: 1050,
      lg: 1280,
      xl: 1920,
    },
  },
})

const styles = {
  cnt: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme2.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  dropdown: {
    padding: "0.5rem",
    "&:hover": {
      backgroundColor: "#efefef",
    },
  },
  dropdownOff: {
    padding: "0.5rem",
    display: "none",
    "&:hover": {
      backgroundColor: "#efefef",
    },
  },
  btnRgt: {
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    [theme2.breakpoints.down("md")]: {
      marginTop: 24,
    },
  },
  btns: {
    justifyContent: "space-between",
    [theme2.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    alignItems: "center",
  },
  btnsDiv: {},
  btnsDiv2: {
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
    display: "flex",
    gap: "2rem",
    alignItems: "center",
  },
  defaultDate: {
    marginRight: "5rem",
  },
  addComp: {
    marginLeft: "1rem",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "1.25rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    height: "2.5rem",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "0.9rem",
    backgroundColor: "#FFFFFF",
    boxShadow: " 0px 0px 60px -18px rgba(0,0,0,0.3)",
    color: "#000000",
    "&:hover": {
      background: "#43d392",
      color: "#ffffff",
    },
    marginLeft: 20,
  },
  buttonVisiblity: {
    display: "none",
  },
  buttonFirst: {
    height: "2.5rem",
    fontFamily: "Inter",
    width: "22rem",
    fontWeight: 500,
    fontSize: "0.9rem",
    backgroundColor: "#FFFFFF",
    boxShadow: " 0px 0px 60px -18px rgba(0,0,0,0.3)",
    color: "#000000",
    justifyContent: "space-between",
    "&:hover": {
      background: "#FFFFFF",
      boxShadow: " 0px 0px 60px -15px rgba(0,0,0,0.3)",
    },
  },
  buttonOn1st: {
    height: "2.5rem",
    fontFamily: "Inter",
    width: "22rem",
    fontWeight: 500,
    fontSize: "0.9rem",
    boxShadow: " 0px 0px 60px -18px rgba(0,0,0,0.3)",
    color: "#ffffff",
    justifyContent: "space-between",
    background: "linear-gradient(107.56deg, #67ABFF 3.15%, #1775EB 95.32%)",
    "&:hover": {
      background: "linear-gradient(107.56deg, #67ABFF 3.15%, #1775EB 95.32%)",
      color: "#ffffff",
    },
  },
  buttonOn: {
    height: "2.5rem",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "0.9rem",
    boxShadow: " 0px 0px 60px -18px rgba(0,0,0,0.3)",
    color: "#ffffff",
    background: "#449A68",
    marginLeft: 20,
  },
  buttonCal: {
    height: "2.5rem",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "0.9rem",
    backgroundColor: "#FFFFFF",
    // boxShadow: ' 0px 0px 60px -18px rgba(0,0,0,0.3)',
    color: "#000000",
    width: "20rem",
    justifyContent: "space-between",
    "&:hover": {
      background: "white",
      boxShadow: " 0px 0px 30px -18px rgba(0,0,0,0.3)",
    },
  },
  cardsSpc: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme2.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  icons: {
    display: "flex",
    width: 60,
    justifyContent: "space-between",
  },
  dpText: {
    marginRight: "20rem",
  },
  dpHead: {
    display: "flex",
    marginTop: "1rem",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
  },
  lineDp: {
    color: "#000",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    marginTop: "1rem",
    marginBottom: "0",
  },
  form: {
    marginLeft: "1.5rem",
  },
  formSelect: {
    height: "2.5rem",
    width: "5rem",
  },
  dpBtns: {
    display: "flex",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    marginTop: "1rem",
    justifyContent: "space-between",
  },
  dpBtnsBtm: {
    display: "flex",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    marginBottom: "1rem",
    marginTop: "1rem",
    justifyContent: "space-between",
  },
  btn1: {
    width: "30%",
    height: "2.3rem",
    backgroundColor: "#efefef",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "10px",
  },
  btn2: {
    width: "30%",
    height: "2.3rem",
    backgroundColor: "#efefef",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
  },
  btn3: {
    width: "30%",
    height: "2.3rem",
    backgroundColor: "#efefef",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn4: {
    width: "20%",
    height: "2.3rem",
    backgroundColor: "#efefef",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  dpTextField: {
    paddingLeft: "1rem",
  },
  dpbtn1: {
    paddingLeft: "1rem",
  },
  dpbtn3: {
    paddingLeft: "1rem",
  },
  dpicon1: {
    paddingRight: "0.8rem",
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.1)",
      cursor: "pointer",
    },
  },
  dpMain: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginTop: "1rem",
    height: "7.2rem",
  },
  dpMain2: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginTop: "1rem",
    height: "5.3rem",
  },
  dpblock1: {
    width: "50%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
  },
  dpblock2: {
    width: "50%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
  },
  btn2btm: {
    width: "40%",
    height: "2.3rem",
    backgroundColor: "#efefef",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  btn3btm: {
    width: "40%",
    height: "2.3rem",
    backgroundColor: "#efefef",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn4btm: {
    width: "20%",
    height: "2.3rem",
    backgroundColor: "#efefef",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
}

export default styles
