import { createSlice } from "@reduxjs/toolkit"

const stores = createSlice({
  name: "stores",
  initialState: {
    name: "",
    locations: [], // Array<{code: '', location: ''}>
    selectedLocations: [],
    cameras: [],
    selectedCameras: [],
    showPredictions: false,
    showFoodStats: false,
  },
  reducers: {
    addLocations: (state, action) => {
      if (action.payload.name) state.name = action.payload.name
      if (action.payload.locations && action.payload.locations.length > 0) {
        state.locations = action.payload.locations
        state.selectedLocations = [action.payload.locations[0]]
      } else {
        state.locations = []
        state.selectedLocations = []
      }
    },
    toggleSelectedLocation: (state, action) => {
      if (state.selectedLocations.find((x) => x.id === action.payload.id)) {
        state.selectedLocations = state.selectedLocations.filter(
          (x) => x.id !== action.payload.id
        )
      } else {
        state.selectedLocations.push(action.payload)
      }
    },
    addCameras: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.cameras = action.payload
        state.selectedCameras = []
      }
    },
    toggleSelectedCameras: (state, action) => {
      if (state.selectedCameras.find((x) => x === action.payload.id)) {
        state.selectedCameras = state.selectedCameras.filter(
          (x) => x !== action.payload.id
        )
      } else {
        state.selectedCameras.push(action.payload.id)
      }
    },
    clearSelectedCameras: (state) => {
      state.selectedCameras = []
    },
    setShowPredictions: (state, action) => {
      state.showPredictions = action.payload
    },
    setShowFoodStats: (state, action) => {
      state.showFoodStats = action.payload
    },
  },
})

export const {
  addLocations,
  toggleSelectedLocation,
  addCameras,
  toggleSelectedCameras,
  setShowFoodStats,
  setShowPredictions,
  clearSelectedCameras,
} = stores.actions
export default stores.reducer
