import { Box, CircularProgress, Typography } from "@mui/material"
import React from "react"

const LoadingContent = ({ loading, error, children }) => {
  return loading || error ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "10rem",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Typography color="error">{error}</Typography>
      )}
    </Box>
  ) : (
    children
  )
}

export default LoadingContent
