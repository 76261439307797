import { createSlice } from '@reduxjs/toolkit';

const id = createSlice({
  name: 'presentDate',
  initialState: {
      value: 1
  },
  reducers: {
    increment: (state, action) => {
        state.value++;
    },
    decrement: (state, action) => {
        state.value--;
    }
  },
});

export const { increment, decrement } = id.actions;
export default id.reducer;
