import { Menu, Button, IconButton } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Calendar from "../assets_icons/Calendar.svg"
import React, { useState } from "react"
import Calendar2 from "./calendar"
import styles from "../style/styleDateSelect"
import Cross from "../assets_icons/cross.svg"
import CalendarMonthRoundedIcon from "../assets_icons/Calendar.svg"
import CancelRoundedIcon from "@mui/icons-material/Cancel"

const useStyles = makeStyles(styles)

function DateSelect({
  from,
  to,
  id,
  dateTemp,
  setDateTemp,
  deleteComparison,
  index,
}) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    console.log(id)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Button style={{ color: "black" }} onClick={handleClick}>
        <div className={classes.icons}>
          <img src={CalendarMonthRoundedIcon} />
        </div>
        {from} - {to}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        className={classes.dropdown}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Calendar2
          setDateTemp={setDateTemp}
          dateTemp={dateTemp}
          id={id}
          handleClose={handleClose}
        />
      </Menu>
      {index !== 0 && (
        <IconButton
          onClick={(e) => deleteComparison(id)}
          color="primary"
          size="small"
          style={{
            marginLeft: "0.3rem",
          }}
        >
          <CancelRoundedIcon />
        </IconButton>
      )}
    </div>
  )
}

export default DateSelect
