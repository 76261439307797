import { createSlice } from '@reduxjs/toolkit';

const date = new Date();
let startMonth = date.getMonth() + 1;
let todayDate = date.getDate();

if (todayDate < 10) {
  todayDate = '0' + todayDate;
}
if (startMonth < 10) {
  startMonth = '0' + startMonth;
}
let endMonth = startMonth;

const from = date.getFullYear() + '-' + startMonth + '-' + todayDate;
const to = date.getFullYear() + '-' + endMonth + '-' + todayDate;

const cardDate = createSlice({
  name: 'presentDate',
  initialState: {
    from: from,
    to: to,
  },
  reducers: {
    addCardDate: (state, action) => {
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
  },
});

export const { addCardDate } = cardDate.actions;
export default cardDate.reducer;
