import { configureStore } from '@reduxjs/toolkit';
import dateRange from './dateSlicers';
import graphData from './graphData';
import presentDate from './presentDateSlicer';
import typeSelected from './typeSelected';
import dataType from './dataType';
import comparisionData from './comparisionData';
import cardDate from './cardDate';
import Ids from './Ids';
import allDates from './allDates';
import stores from './stores';

const store = configureStore({
  reducer: {
    dateRange,
    graphData,
    presentDate,
    typeSelected,
    dataType,
    comparisionData,
    cardDate,
    Ids,
    allDates,
    stores,
  },
});

export default store;
