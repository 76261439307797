import { createContext, useContext, useEffect, useRef, useState } from 'react';
import config from '../config/index.js';
import { useInterval } from '../hooks/useInterval';
import { Auth } from 'aws-amplify';

var W3CWebSocket = require('websocket').w3cwebsocket;

const SocketContext = createContext(null);

export function SocketContextWrapper({ children }) {
  const socket = useRef(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const getSocket = async () => {
      let socketUrl = '';
      try {
        let session = await Auth.currentSession();
        let token = session.getAccessToken().getJwtToken();
        socketUrl = `${config.webSocketURL}?token=${token}`;
      } catch (e) {
        console.log(e);
        socketUrl = `${config.webSocketURL}`;
      }
      socket.current = new W3CWebSocket(socketUrl);
      socket.current.onopen = () => {
        setOpen(true);
      };
      socket.current.onclose = () => {
        setOpen(false);
      };
    };
    getSocket();

    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (open) {
      socket.current.onmessage = (evt) => {
        const recievedMessage = JSON.parse(evt.data);
        setMessage(recievedMessage);
      };
    }
  }, [open]);

  const hearbeat = () => {
    if (open) {
      socket.current.send(JSON.stringify({ action: 'ping' }));
    }
  };
  useInterval(hearbeat, 300000);

  return <SocketContext.Provider value={{ message }}>{children}</SocketContext.Provider>;
}

export function useSocketContext() {
  return useContext(SocketContext);
}
