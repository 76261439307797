import React from "react"
import Main from "../assets_icons/main_icon.png"
import Login from "../components/Login"
import Register from "../components/Register"
import { Grid } from "@mui/material"

export const LoginPage = () => {
  const [login, setLogin] = React.useState(true)

  const setToLogin = () => setLogin(true)
  const setToRegister = () => setLogin(false)

  return (
    <Grid
      container
      sx={{ height: "100vh", overflow: "hidden" }}
      alignItems="center"
    >
      <Grid
        item
        xs={6}
        paddingRight={{ xs: 0, lg: 20 }}
        paddingLeft={{ xs: 5, lg: 10 }}
      >
        {login ? (
          <Login setToRegister={setToRegister} />
        ) : (
          <Register setToLogin={setToLogin} />
        )}
      </Grid>
      <Grid item xs={6}>
        <img src={Main} style={{ width: "100%" }} />
      </Grid>
    </Grid>
  )
}
