import { useRef, useEffect, useState } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded"
import PauseRoundedIcon from "@mui/icons-material/PauseRounded"
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded"
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import { IconButton } from "@mui/material"
import { Link } from "react-router-dom"

const VideoFeed = ({ camera, edit }) => {
  const videoRef = useRef(null)
  const [player, setPlayer] = useState()
  const [showToolbar, setShowToolbar] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  console.log(camera)

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!player) {
      const videoElement = videoRef.current
      if (!videoElement) return

      setPlayer(
        videojs(videoElement, {}, () => {
          console.log("player is ready")
        })
      )
    }
  }, [videoRef])

  useEffect(() => {
    return () => {
      if (player) {
        player.dispose()
      }
    }
  }, [player])

  const toggleVideo = () => {
    if (videoRef?.current) {
      if (videoRef.current.paused) videoRef.current.play()
      else videoRef.current.pause()
    }
  }

  useEffect(() => {
    videoRef.current.addEventListener("play", () => {
      setIsPlaying(true)
    })
    videoRef.current.addEventListener("pause", () => {
      setIsPlaying(false)
    })
  }, [])

  const enterVideoFullscreen = () => {
    if (videoRef?.current) {
      videoRef.current.requestFullscreen()
    }
  }

  const exitVideoFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    }
  }

  return (
    <div
      onMouseEnter={() => setShowToolbar(true)}
      onMouseLeave={() => setShowToolbar(false)}
      style={{
        width: "100%",
        height: "auto",
        position: "relative",
        aspectRatio: "16/9",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "20px",
      }}
    >
      <video
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: -1,
          borderRadius: "20px",
        }}
        className="video-js"
        ref={videoRef}
      >
        <source src={camera?.streamUrl} type="application/x-mpegURL" />
      </video>
      {showToolbar && videoRef && videoRef.current && (
        <div
          style={{
            padding: "0.5rem",
            background: "white",
            borderRadius: "15px",
            left: "1rem",
            right: "1rem",
            position: "absolute",
            bottom: "1rem",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <IconButton onClick={toggleVideo}>
            {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
          </IconButton>
          <IconButton onClick={enterVideoFullscreen}>
            <FullscreenRoundedIcon />
          </IconButton>
          <Link to={"/camera/view/" + camera.id}>
            <IconButton>
              <RemoveRedEyeRoundedIcon />
            </IconButton>
          </Link>

          <IconButton onClick={edit}>
            <EditRoundedIcon />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default VideoFeed
