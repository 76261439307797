import { createSlice } from '@reduxjs/toolkit';
import {
  getAllHoursInRange,
  getAllWeekDaysInRange,
  getAllWeeksInRange,
  getAllMonthsInRange,
  getAllDaysInRange,
} from '../utilities/functions';

const allDates = createSlice({
  name: 'allDates',
  initialState: {
    hour: getAllHoursInRange(new Date(), new Date()),
    weekDay: getAllWeekDaysInRange(new Date(), new Date()),
    week: getAllWeeksInRange(new Date(), new Date()),
    date: getAllDaysInRange(new Date(), new Date()),
    month: getAllMonthsInRange(new Date(), new Date()),
  },
  reducers: {
    addAllDate: (state, action) => {
      state.hour = action.payload.hour;
      state.weekDay = action.payload.weekDay;
      state.week = action.payload.week;
      state.date = action.payload.date;
      state.month = action.payload.month;
    },
  },
});

export const { addAllDate } = allDates.actions;
export default allDates.reducer;
