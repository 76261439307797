import { createSlice } from '@reduxjs/toolkit';

const dataTypeSlice = createSlice({
  name: 'dataSelected',
  initialState: {
    type: 'totalVisits',
  },
  reducers: {
    changeDataType: (state, action) => {
      state.type = action.payload.type;
    },
  },
});

export const { changeDataType } = dataTypeSlice.actions;
export default dataTypeSlice.reducer;
