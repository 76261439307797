import { useEffect, useRef, useState } from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { Button, Grid, IconButton, Menu } from "@mui/material"
import DemoVideo from "../assets_icons/demo.mp4"
import FullscreenIcon from "../assets_icons/fullscreen.png"
import ViewIcon from "../assets_icons/view.png"
import PlayIcon from "../assets_icons/play.png"
import PauseIcon from "../assets_icons/pause.png"
import HLSPlayer from "react-hls"
import AddCameraView from "../layouts/add-camera-view"
import AddCamera from "../layouts/add-camera"
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded"
import PauseRoundedIcon from "@mui/icons-material/PauseRounded"
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded"
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded"
import AddRoundedIcon from "@mui/icons-material/AddRounded"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded"
import { useQuery } from "react-query"
import { getAllViews, getView } from "../api/camera"
import VideoFeed from "../components/VideoFeed"

const CameraFeed = () => {
  const [addView, setAddView] = useState(false)
  const [editView, setEditView] = useState(false)
  const [addCamera, setAddCamera] = useState(false)
  const [selectedCameraId, setSelectedCameraId] = useState(null)
  const [view, setView] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const isViewsMenuOpen = Boolean(anchorEl)

  const openAddView = () => setAddView(true)
  const closeAddView = () => setAddView(false)

  const openEditView = () => setEditView(true)
  const closeEditView = () => setEditView(false)

  const openAddCamera = (id = null) => {
    setSelectedCameraId(id)
    setAddCamera(true)
  }
  const closeAddCamera = () => {
    setSelectedCameraId(null)
    setAddCamera(false)
  }
  const openViewsMenu = (event) => setAnchorEl(event.currentTarget)
  const closeViewsMenu = (view) => {
    setView(view)
    setAnchorEl(null)
  }

  const {
    isLoading: viewsLoading,
    error: viewsError,
    data: { data: views, message: viewsMessage } = { data: [], message: "" },
    refetch: refetchViews,
  } = useQuery("getAllViews", getAllViews)

  const refreshView = async () => {
    const res = await getView(view.id)
    if (res.data) setView(res.data)
    refetchViews()
  }

  useEffect(() => {
    if (views && views.length)
      setView(views.find((view) => view.default) || views[0])
  }, [views])

  return (
    <>
      <AddCameraView open={addView} close={closeAddView} />

      <AddCameraView
        open={editView}
        close={closeEditView}
        edit
        viewData={view}
        refresh={refreshView}
      />

      <AddCamera
        open={addCamera}
        close={closeAddCamera}
        id={selectedCameraId}
      />
      <h1>Live View</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{
              color: "black",
            }}
            endIcon={<KeyboardArrowDownRoundedIcon />}
            onClick={openViewsMenu}
          >
            {view?.name || "Select a View"}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={isViewsMenuOpen}
            onClose={() => closeViewsMenu(view)}
          >
            {(!views || views.length === 0) && (
              <div style={{ padding: "0 10px" }}>No Views Found</div>
            )}
            {views &&
              views.map((v) => (
                <MenuItem key={v.id} onClick={() => closeViewsMenu(v)}>
                  {v.name}
                </MenuItem>
              ))}
          </Menu>
          <Button
            style={{ marginLeft: "1rem", height: "3rem" }}
            onClick={openAddView}
            color="primary"
            size="large"
            startIcon={<AddRoundedIcon />}
          >
            Add View
          </Button>
          <Button
            style={{ marginLeft: "1rem", height: "3rem" }}
            onClick={openEditView}
            color="primary"
            size="large"
            startIcon={<EditRoundedIcon />}
          >
            Edit View
          </Button>
        </div>
        <Button
          variant="contained"
          style={{ height: "3rem" }}
          onClick={() => openAddCamera()}
          color="primary"
          size="large"
          startIcon={<AddRoundedIcon />}
        >
          Add Camera
        </Button>
      </div>
      <Grid container spacing={3} style={{ marginTop: "1rem" }}>
        {view &&
          view.cameras?.map((cam) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <VideoFeed
                camera={cam}
                key={cam.id}
                edit={() => openAddCamera(cam.id)}
              />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export default CameraFeed
