import { useState, useContext } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  DialogActions,
  useTheme,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material"
import { createLocation, updateLocation } from "../api"
import { timeZoneList } from "../utilities/constants"
import { useEffect } from "react"

const AddLocation = ({
  open,
  onClose,
  refresh,
  toast,
  opening,
  closing,
  timezone,
  data,
}) => {
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const [locationName, setLocationName] = useState("")
  const [address, setAddress] = useState("")
  const [code, setCode] = useState("")
  const [timeZone, setTimeZone] = useState(timezone || "")
  const [openingTime, setOpeningTime] = useState(opening || "")
  const [closingTime, setClosingTime] = useState(closing || "")
  const [isDefault, setIsDefault] = useState(false)
  const [weekSchedule, setWeekSchedule] = useState([])
  const [dashboardUpdateDuration, setDashboardUpdateDuration] = useState("")

  const handleWeekSchedule = (day) => {
    if (weekSchedule.includes(day)) {
      setWeekSchedule((weekSchedule) => weekSchedule.filter((d) => d !== day))
    } else {
      setWeekSchedule((weekSchedule) => [...weekSchedule, day])
    }
  }

  const addLocation = () => {
    setLoading(true)
    const newLoc = {
      name: locationName,
      address,
      code,
      timezone: timeZone,
      opening: openingTime,
      closing: closingTime,
      schedule: weekSchedule,
      dashboardDuration: parseInt(dashboardUpdateDuration),
    }
    if (data)
      updateLocation(data.id, {
        ...data,
        ...newLoc,
      })
        .then(() => {
          toast("Location updated successfully!", "success")
          refresh()
          onClose()
        })
        .catch((err) => {
          toast(err?.data?.message || "Something went wrong")
        })
        .finally(() => setLoading(false))
    else
      createLocation(newLoc)
        .then(() => {
          toast("Location added successfully!", "success")
          refresh()
          onClose()
        })
        .catch((err) => {
          toast(err?.data?.message || "Something went wrong")
        })
        .finally(() => setLoading(false))
  }

  useEffect(() => {
    console.log(data)
    if (data) {
      setLocationName(data.name)
      setAddress(data.address)
      setCode(data.code)
      setTimeZone(data.timezone)
      setOpeningTime(data.opening)
      setClosingTime(data.closing)
      setWeekSchedule(data.schedule)
      setDashboardUpdateDuration(data.dashboardDuration)
    } else {
      setLocationName("")
      setAddress("")
      setCode("")
      setTimeZone(timezone || "")
      setOpeningTime(opening || "")
      setClosingTime(closing || "")
      setWeekSchedule([])
      setDashboardUpdateDuration("")
    }
  }, [data])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      PaperProps={{ style: { width: "50%" } }}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: 700,
            textAlign: "center",
          }}
          variant="h5"
          color="primary"
        >
          Add New Location
        </Typography>
      </DialogTitle>
      <DialogContent>
        <label
          style={{ marginBottom: "5px", marginTop: "10px", display: "block" }}
        >
          Location Name
        </label>
        <TextField
          style={{ marginTop: "0.3rem" }}
          variant="outlined"
          value={locationName}
          onChange={(e) => setLocationName(e.target.value)}
          placeholder="Enter Location Name"
          fullWidth
        />
        <label
          style={{ marginBottom: "5px", marginTop: "10px", display: "block" }}
        >
          Address
        </label>
        <TextField
          style={{ marginTop: "0.3rem" }}
          variant="outlined"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Enter Address"
          fullWidth
        />
        <label
          style={{ marginBottom: "5px", marginTop: "10px", display: "block" }}
        >
          Code
        </label>
        <TextField
          style={{ marginTop: "0.3rem" }}
          variant="outlined"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter Code"
          fullWidth
        />
        <label
          style={{ marginBottom: "5px", marginTop: "10px", display: "block" }}
        >
          Time Zone
        </label>
        <FormControl fullWidth>
          <InputLabel>Enter Time Zone</InputLabel>
          <Select
            value={timeZone}
            label="Time Zone"
            name="timeZone"
            onChange={(e) => setTimeZone(e.target.value)}
          >
            {timeZoneList.map((t) => (
              <MenuItem value={t}>{t}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <label
          style={{ marginBottom: "5px", marginTop: "10px", display: "block" }}
        >
          Opening Time
        </label>
        <TextField
          value={openingTime}
          onChange={(e) => setOpeningTime(e.target.value)}
          required
          type="time"
          placeholder="Opening Time"
          fullWidth
        />
        <label
          style={{ marginBottom: "5px", marginTop: "10px", display: "block" }}
        >
          Closing Time
        </label>
        <TextField
          value={closingTime}
          onChange={(e) => setClosingTime(e.target.value)}
          required
          type="time"
          placeholder="Closing Time"
          fullWidth
        />
        <label
          style={{ marginBottom: "5px", marginTop: "10px", display: "block" }}
        >
          Dashboard Update Duration
        </label>
        <TextField
          value={dashboardUpdateDuration}
          onChange={(e) => setDashboardUpdateDuration(e.target.value)}
          required
          type="number"
          placeholder="Enter Dashboard Update Duration"
          fullWidth
        />
        <div style={{ marginTop: "1rem" }}>
          {[
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ].map((day) => (
            <Button
              style={{
                marginRight: "1rem",
                border: "1px solid black",
                background: weekSchedule.includes(day)
                  ? theme.palette.primary.main
                  : theme.palette.primary.contrastText,
                color: weekSchedule.includes(day) ? "white" : "black",
              }}
              variant="contained"
              key={day}
              onClick={() => handleWeekSchedule(day)}
            >
              {day}
            </Button>
          ))}
        </div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={isDefault}
                onChange={(e) => setIsDefault(e.target.checked)}
              />
            }
            label="Is Default Location"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1.3rem 1.5rem",
        }}
      >
        <Button onClick={onClose} size="large">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={addLocation}
        >
          {loading ? (
            <CircularProgress size={"1.4rem"} color="inherit" />
          ) : data ? (
            "Edit"
          ) : (
            "Add"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddLocation
