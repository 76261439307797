import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Grid,
  Button,
  Menu,
  Typography,
  FormControlLabel,
  Switch,
  useTheme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMore"
import { deleteDate } from "../redux/dateSlicers"
import { removeDate } from "../redux/presentDateSlicer"
import { changeType } from "../redux/typeSelected"
import { changeDataType } from "../redux/dataType"
import { changeGraphData } from "../redux/graphData"
import { getFoodCardsData, getPeopleCardsData } from "../api"
import CardLayout from "../components/card"
import Graph from "../components/Graph"
import DateSelectBtm from "../components/dateSelectBtm"
import Card1 from "../assets_icons/card-1.svg"
import Card2 from "../assets_icons/card-2.svg"
import Card3 from "../assets_icons/card-3.svg"
import Card4 from "../assets_icons/card-4.svg"
import styles from "../style/styleApp"
import AddComparision from "../components/AddComparision"
import { decrement } from "../redux/Ids"
import { Toggle } from "../components/styled"
import { getAllCameras } from "../api/camera"
import { useQuery } from "react-query"
import {
  addCameras,
  toggleSelectedCameras,
  setShowFoodStats,
  setShowPredictions,
  clearSelectedCameras,
} from "../redux/stores"

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

let date = new Date().getDate()
date = date < 10 ? "0" + date : date
let month = new Date().getMonth() + 1
month = month < 10 ? "0" + month : month
const currentDate = new Date().getFullYear() + "-" + month + "-" + date

const useStyles = makeStyles(styles)

const Home = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const dates = useSelector((state) => state.dateRange)
  const cardDate = useSelector((state) => state.cardDate)
  const storesData = useSelector((state) => state.stores)
  const presentDate = useSelector((state) => state.presentDate)
  const JsonObject = dates.map(JSON.stringify)
  const uniqueArray = Array.from(new Set(JsonObject)).map(JSON.parse)
  const graphData = useSelector((state) => state.graphData)
  const [anchorEl, setAnchorEl] = useState(null)
  const [value, setValue] = useState("Total tickets")
  const [comparisonIds, setComparisonIds] = useState([1, 2, 3])
  const [showDatesFilter, setShowDatesFilter] = useState(true)
  useQuery("getAllCameras", getAllCameras, {
    onSuccess: (resData) => {
      console.log(resData.data)
      dispatch(addCameras(resData.data))
    },
  })
  const [clicked, setClicked] = useState({
    b1: false,
    b2: true,
    b3: false,
    b4: false,
    b5: false,
    b6: false,
  })
  const [btnVisiblity, setBtnVisiblity] = useState({
    b2: true,
    b3: true,
    b4: true,
    b5: true,
    b6: true,
  })
  const [dataType, setDataType] = useState({
    averageHourlyVisits: false,
    averageDailyVisits: false,
    totalVisits: true,
    maxVisits: false,
    minEmpty: true,
  })
  const [cardData, setCardData] = useState({
    averageHourlyVisits: {
      value: null,
      change: null,
    },
    totalVisits: {
      value: null,
      change: null,
    },
    maxVisits: {
      value: null,
      change: null,
    },
    minEmpty: {
      value: null,
      change: null,
    },
  })
  const [dateTemp, setDateTemp] = useState({
    from:
      months[new Date().getMonth()] +
      " " +
      new Date().getDate() +
      ", " +
      new Date().getFullYear(),
    to:
      months[new Date().getMonth()] +
      " " +
      new Date().getDate() +
      ", " +
      new Date().getFullYear(),
  })

  useEffect(() => {
    if (showDatesFilter) dispatch(clearSelectedCameras())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDatesFilter])

  useEffect(() => {
    const locationIds =
      storesData.selectedLocations.length > 0
        ? storesData.selectedLocations.map((l) => l.code)
        : undefined
    const cameraIds =
      storesData.selectedCameras.length > 0
        ? storesData.selectedCameras
        : undefined
    if (storesData.showFoodStats) {
      getFoodCardsData(
        cardDate.from,
        cardDate.to,
        storesData.showPredictions,
        locationIds,
        cameraIds
      ).then((res) => setCardData(res.data))
    } else {
      getPeopleCardsData(
        cardDate.from,
        cardDate.to,
        storesData.showPredictions,
        locationIds,
        cameraIds
      ).then((res) => setCardData(res.data))
    }
    if (comparisonIds.includes(presentDate.id)) {
      setComparisonIds([...comparisonIds])
    } else {
      setComparisonIds([...comparisonIds, presentDate.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storesData.showFoodStats,
    storesData.showPredictions,
    cardDate,
    storesData.selectedLocations,
  ])

  const addComparison = () => {
    console.log(comparisonIds)
  }

  const deleteComparison = (id) => {
    dispatch(deleteDate({ id }))
    handleGraphData(id)
    setComparisonIds((c) => c.filter((x) => x !== id))
    dispatch(decrement())
  }

  const handleGraphData = (id) => {
    let obj_hour = {}
    Object.keys(graphData.hour).map((key) => {
      const arr = JSON.parse(JSON.stringify(graphData.hour[key]))
      for (let i = 0; i < arr.length; i++) {
        delete arr[i]["value" + id]
        if (!Object.keys(arr[i]).find((x) => x.includes("value"))) {
          arr.splice(i, 1)
          i--
        }
      }
      obj_hour[key] = arr
    })
    let obj_weekDay = {}
    Object.keys(graphData.weekDay).map((key) => {
      const arr = JSON.parse(JSON.stringify(graphData.hour[key]))
      for (let i = 0; i < arr.length; i++) {
        delete arr[i]["value" + id]
        if (!Object.keys(arr[i]).find((x) => x.includes("value"))) {
          arr.splice(i, 1)
          i--
        }
      }
      obj_weekDay[key] = arr
    })
    let obj_week = {}
    Object.keys(graphData.week).map((key) => {
      const arr = JSON.parse(JSON.stringify(graphData.week[key]))
      for (let i = 0; i < arr.length; i++) {
        delete arr[i]["value" + id]
        if (!Object.keys(arr[i]).find((x) => x.includes("value"))) {
          arr.splice(i, 1)
          i--
        }
      }
      obj_week[key] = arr
    })
    let obj_date = {}
    Object.keys(graphData.date).map((key) => {
      const arr = JSON.parse(JSON.stringify(graphData.date[key]))
      for (let i = 0; i < arr.length; i++) {
        delete arr[i]["value" + id]
        if (!Object.keys(arr[i]).find((x) => x.includes("value"))) {
          arr.splice(i, 1)
          i--
        }
      }
      obj_date[key] = arr
    })
    let obj_month = {}
    Object.keys(graphData.month).map((key) => {
      const arr = JSON.parse(JSON.stringify(graphData.month[key]))
      for (let i = 0; i < arr.length; i++) {
        delete arr[i]["value" + id]
        if (!Object.keys(arr[i]).find((x) => x.includes("value"))) {
          arr.splice(i, 1)
          i--
        }
      }
      obj_month[key] = arr
    })
    dispatch(
      changeGraphData({
        hour: obj_hour,
        weekDay: obj_weekDay,
        week: obj_week,
        month: obj_month,
        date: obj_date,
      })
    )

    dispatch(removeDate())
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleHour = () => {
    setClicked({ b2: true })
    dispatch(
      changeType({
        type: "hour",
      })
    )
    setDataType({
      averageHourlyVisits: false,
      averageDailyVisits: false,
      totalVisits: true,
      maxVisits: false,
      minEmpty: true,
    })
  }
  const handleDate = () => {
    setClicked({ b4: true })
    dispatch(
      changeType({
        type: "date",
      })
    )
    setDataType({
      averageHourlyVisits: true,
      averageDailyVisits: false,
      totalVisits: true,
      maxVisits: true,
      minEmpty: true,
    })
  }
  const handleMonth = () => {
    setClicked({ b6: true })
    dispatch(
      changeType({
        type: "month",
      })
    )
    setDataType({
      averageHourlyVisits: true,
      averageDailyVisits: true,
      totalVisits: true,
      maxVisits: true,
      minEmpty: true,
    })
  }
  const handleWeekday = () => {
    setClicked({ b3: true })
    dispatch(
      changeType({
        type: "weekDay",
      })
    )
    setDataType({
      averageHourlyVisits: true,
      averageDailyVisits: true,
      totalVisits: true,
      maxVisits: false,
      minEmpty: true,
    })
  }
  const handleWeek = () => {
    setClicked({ b5: true })
    dispatch(
      changeType({
        type: "week",
      })
    )
    setDataType({
      averageHourlyVisits: true,
      averageDailyVisits: true,
      totalVisits: true,
      maxVisits: true,
      minEmpty: true,
    })
  }

  let valueHr = cardData?.minEmpty?.value || 0
  const hr = Math.floor(Math.round((valueHr / 60) * 100) / 100)

  let valueMin = cardData?.minEmpty?.value || 0
  const min = Math.floor(Math.round((valueMin % 60) * 100) / 100)

  const toggleLocCam = (id) => {
    dispatch(toggleSelectedCameras({ id }))
  }

  return (
    <>
      <div className={classes.cardsSpc}>
        <Grid className={classes.cnt} container>
          <Grid item>
            <CardLayout
              card={Card1}
              label="Total visits"
              count={cardData?.totalVisits?.value || 0}
              change={
                Math.round((cardData?.totalVisits?.change || 0) * 100) / 100
              }
            />
          </Grid>
          <Grid item>
            <CardLayout
              card={Card2}
              label="Average hourly visits"
              count={
                Math.round((cardData?.averageHourlyVisits?.value || 0) * 100) /
                100
              }
              change={
                Math.round((cardData?.averageHourlyVisits?.change || 0) * 100) /
                100
              }
            />
          </Grid>
          <Grid item>
            <CardLayout
              card={Card3}
              label="30-min peak visits max"
              count={cardData?.maxVisits?.value || 0}
              change={
                Math.round((cardData?.maxVisits?.change || 0) * 100) / 100
              }
            />
          </Grid>
          <Grid item>
            <CardLayout
              cardType="minEmpty"
              card={Card4}
              label="Zero visits duration (Hrs/Min)"
              countHr={hr}
              countMin={min}
              change={Math.round((cardData?.minEmpty?.change || 0) * 100) / 100}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.btnsDiv2}>
        <Toggle
          val1={true}
          val2={false}
          label1="Food"
          label2="People"
          selected={storesData.showFoodStats}
          setSelected={(val) => dispatch(setShowFoodStats(val))}
        />

        <FormControlLabel
          color="primary"
          control={
            <Switch
              checked={storesData.showPredictions}
              onChange={() =>
                dispatch(setShowPredictions(!storesData.showPredictions))
              }
            />
          }
          label="Predictions"
          labelPlacement="start"
        />

        <Toggle
          val1={false}
          val2={true}
          label1="Location/Camera"
          label2="Dates"
          selected={showDatesFilter}
          setSelected={setShowDatesFilter}
        />

        <div style={{ overflowX: "auto", display: "flex" }}>
          {!showDatesFilter &&
            storesData.selectedLocations.map((l, i) => (
              <>
                {storesData.cameras
                  .filter((c) =>
                    storesData.showFoodStats
                      ? c.detectionType === "Food"
                      : c.detectionType === "People"
                  )
                  .map((c, j) => (
                    <Button
                      variant="contained"
                      onClick={() => toggleLocCam(c.id)}
                      style={{
                        height: "3rem",
                        minWidth: "fit-content",
                        marginLeft: i === 0 && j === 0 ? 0 : 20,
                        background: storesData.selectedCameras.includes(c.id)
                          ? theme.palette.primary.main
                          : "white",
                        color: storesData.selectedCameras.includes(c.id)
                          ? "white"
                          : "black",
                      }}
                    >
                      {l.name} + {c.name}
                    </Button>
                  ))}
              </>
            ))}
          {showDatesFilter &&
            uniqueArray.map((item) => {
              return (
                <DateSelectBtm
                  dateTemp={dateTemp}
                  setDateTemp={setDateTemp}
                  from={item.from}
                  to={item.to}
                  id={item.id}
                  deleteComparison={deleteComparison}
                  index={uniqueArray.indexOf(item)}
                />
              )
            })}
        </div>
        {showDatesFilter && uniqueArray.length && (
          <AddComparision
            comparisonIds={comparisonIds}
            setComparisonIds={setComparisonIds}
            addComparison={addComparison}
            setDateTemp={setDateTemp}
            dateTemp={dateTemp}
          />
        )}
      </div>
      <div className={classes.btnsDiv}>
        <Grid container className={classes.btns}>
          <div>
            <Grid>
              <Button
                sx={{ color: "black" }}
                onClick={handleClick}
                endIcon={<ExpandMoreSharpIcon />}
              >
                {value}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ disableScrollLock: true }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "20rem",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={
                      dataType.totalVisits
                        ? classes.dropdown
                        : classes.dropdownOff
                    }
                    onClick={() => {
                      handleClose()
                      setValue("Total visits")
                      dispatch(changeDataType({ type: "totalVisits" }))
                      setBtnVisiblity({
                        b2: true,
                        b3: true,
                        b4: true,
                        b5: true,
                        b6: true,
                      })
                    }}
                  >
                    Total visits
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={
                      dataType.maxVisits
                        ? classes.dropdown
                        : classes.dropdownOff
                    }
                    onClick={() => {
                      handleClose()
                      setValue("Maximum visits in 30 minutes")
                      dispatch(changeDataType({ type: "maxVisits" }))
                      setBtnVisiblity({
                        b2: false,
                        b3: false,
                        b4: true,
                        b5: true,
                        b6: true,
                      })
                    }}
                  >
                    Maximum visits in 30 minutes
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={
                      dataType.minEmpty ? classes.dropdown : classes.dropdownOff
                    }
                    onClick={() => {
                      handleClose()
                      setValue("Hour/Minutes with 0 visits")
                      dispatch(changeDataType({ type: "minEmpty" }))
                      setBtnVisiblity({
                        b2: true,
                        b3: true,
                        b4: true,
                        b5: true,
                        b6: true,
                      })
                    }}
                  >
                    Hour/Minutes with 0 visits
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={
                      dataType.averageHourlyVisits
                        ? classes.dropdown
                        : classes.dropdownOff
                    }
                    onClick={() => {
                      handleClose()
                      setValue("Average hourly visits")
                      dispatch(changeDataType({ type: "averageHourlyVisits" }))
                      setBtnVisiblity({
                        b2: false,
                        b3: true,
                        b4: true,
                        b5: true,
                        b6: true,
                      })
                    }}
                  >
                    Average hourly visits
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={
                      dataType.averageDailyVisits
                        ? classes.dropdown
                        : classes.dropdownOff
                    }
                    onClick={() => {
                      handleClose()
                      setValue("Average daily visits")
                      dispatch(changeDataType({ type: "averageDailyVisits" }))
                      setBtnVisiblity({
                        b2: false,
                        b3: true,
                        b4: false,
                        b5: true,
                        b6: true,
                      })
                    }}
                  >
                    Average monthly resolutions
                  </Typography>
                </div>
              </Menu>
            </Grid>
          </div>
          <div className={classes.btnRgt}>
            <Grid
              items
              className={btnVisiblity.b2 ? null : classes.buttonVisiblity}
            >
              <Button
                onClick={handleHour}
                variant={clicked.b2 ? "contained" : "text"}
              >
                Hour of the day
              </Button>
            </Grid>
            <Grid
              items
              className={btnVisiblity.b3 ? null : classes.buttonVisiblity}
            >
              <Button
                onClick={handleWeekday}
                variant={clicked.b3 ? "contained" : "text"}
              >
                Weekday
              </Button>
            </Grid>
            <Grid
              items
              className={btnVisiblity.b4 ? null : classes.buttonVisiblity}
            >
              <Button
                onClick={handleDate}
                variant={clicked.b4 ? "contained" : "text"}
              >
                Date
              </Button>
            </Grid>
            <Grid
              items
              className={btnVisiblity.b5 ? null : classes.buttonVisiblity}
            >
              <Button
                onClick={handleWeek}
                variant={clicked.b5 ? "contained" : "text"}
              >
                Week
              </Button>
            </Grid>
            <Grid
              items
              className={btnVisiblity.b6 ? null : classes.buttonVisiblity}
            >
              <Button
                onClick={handleMonth}
                variant={clicked.b6 ? "contained" : "text"}
              >
                Month
              </Button>
            </Grid>
          </div>
        </Grid>
      </div>
      <Graph
        currentDate={currentDate}
        setComparisonIds={setComparisonIds}
        comparisonIds={comparisonIds}
      />
    </>
  )
}

export default Home
