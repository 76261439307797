/* eslint-disable import/no-webpack-loader-syntax */
import { Paper } from "@mui/material"
import Map, { Marker, Popup } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import { useState } from "react"
import mapboxgl from "mapbox-gl"
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

function ReactMap({ markers, carIcon }) {
  return (
    <Paper sx={{ overflow: "hidden" }}>
      <Map
        initialViewState={{
          latitude: 23.9739451,
          longitude: 45.0060422,
          height: "100%",
          zoom: 4,
        }}
        style={{ width: "100%", height: "65vh" }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken="pk.eyJ1Ijoic2hvcHJveCIsImEiOiJja2t2NGNtaDUxZGwwMm9wY2V6azZyM2EwIn0.s5Ona_7VnPtgL2YrNV1S6g"
      >
        {markers.map((marker) => (
          <Pin {...marker} carIcon={carIcon} />
        ))}
      </Map>
    </Paper>
  )
}

const Pin = ({ carIcon, ...props }) => {
  const [hover, setHover] = useState(false)
  return (
    <>
      <Marker
        latitude={props?.latitude || 40}
        longitude={props?.longitude || 40}
        anchor="bottom"
      >
        {carIcon ? (
          <DirectionsCarIcon
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            sx={{ stroke: "#fff", strokeWidth: 0.5 }}
          />
        ) : (
          <LocationOnIcon
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            sx={{ stroke: "#fff", strokeWidth: 1 }}
          />
        )}
      </Marker>
      {hover && (
        <Popup
          latitude={props?.latitude || 40}
          longitude={props?.longitude || 40}
          anchor="top"
          closeButton={false}
        >
          <b>{props?.detectionType}</b> <br />({props?.latitude?.toFixed(2)},{" "}
          {props?.longitude?.toFixed(2)})
        </Popup>
      )}
    </>
  )
}

export default ReactMap
