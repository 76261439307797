import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  DialogActions,
  CircularProgress,
} from "@mui/material"
import { useState, useEffect } from "react"
import { editTicket, serachStaff } from "../api/new-project"

const AcknowledgeModal = ({ open, close, ticket, refresh, resolve }) => {
  const [details, setDetails] = useState("")
  const [staff, setStaff] = useState([])
  const [assignee, setAssignee] = useState(null)
  const [estimatedResolution, setEstimatedResolution] = useState(new Date())
  const [loading, setLoading] = useState(false)

  const handleStaffSearchChange = async (val) => {
    const { data = [] } = await serachStaff({
      name: val,
      staffType: "",
    })
    setStaff(data)
  }

  const assignTicket = async () => {
    setLoading(true)
    await editTicket(
      ticket.id,
      resolve
        ? { ...ticket, actualResolution: new Date(), status: "Resolved" }
        : {
            ...ticket,
            assignee: assignee.id,
            estimatedResolution: new Date(estimatedResolution),
            status: "Assigned",
          }
    )
      .then(() => {
        refresh()
        close()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    handleStaffSearchChange("")
  }, [])

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="sm"
      PaperProps={{ style: { width: "80%" } }}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: 700,
            textAlign: "center",
          }}
          variant="h5"
          color="primary"
        >
          Provide more details
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!resolve && (
          <Grid container sx={{ pt: 2 }} spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={staff}
                getOptionLabel={(option) => option?.name || ""}
                value={assignee}
                onChange={(_, newVal) => setAssignee(newVal)}
                onInputChange={(_, newInputValue) =>
                  handleStaffSearchChange(newInputValue)
                }
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Assignee"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Estimated resolution date"
                InputLabelProps={{ shrink: true }}
                value={estimatedResolution}
                onChange={(e) => setEstimatedResolution(e.target.value)}
                fullWidth
                type="date"
              />
            </Grid>
          </Grid>
        )}

        <TextField
          label="Details"
          fullWidth
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          multiline
          rows={5}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={assignTicket} variant="contained">
          {loading ? <CircularProgress /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AcknowledgeModal
