import { createSlice } from '@reduxjs/toolkit';

const typeSelectedSlice = createSlice({
  name: 'typeSelected',
  initialState: {
    type: 'hour',
  },
  reducers: {
    changeType: (state, action) => {
      state.type = action.payload.type;
    },
  },
});

export const { changeType } = typeSelectedSlice.actions;
export default typeSelectedSlice.reducer;
