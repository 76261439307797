import { InputAdornment, TextField } from "@mui/material"

export const IconInput = ({ Icon, style, placeholder, type, ...rest }) => {
  return (
    <>
      <TextField
        sx={{
          my: '0.2rem',
          '& .MuiOutlinedInput-root': {
            input: {
              padding: '1.1rem',
            },
          },
        }}
        // error
        helperText=' '
        {...rest}
        type={type}
        variant='outlined'
        InputProps={{
          startAdornment: <InputAdornment position='start'>{Icon}</InputAdornment>,
        }}
        style={{ ...style }}
        placeholder={placeholder}
      />
    </>
  );
}
