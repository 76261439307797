const styles = {
  nav: {
    boxShadow: "none !important",
    height: "5.5rem",
    paddingTop: "1.2rem",
    alignItems: "bottom",
    display: "flex",
  },
  navEle: {
    padding: 0,
    position: "relative",
  },
  heading: {
    marginLeft: "1rem !important",
    marginRight: "1rem !important",
    fontWeight: "700 !important",
  },
  subHeading: {
    fontWeight: "500 !important",
    marginLeft: "1rem !important",
    marginRight: "1rem !important",
    flexGrow: 1,
  },
  headingRight: {
    ontWeight: 500,
    color: "#000000",
    fontFamily: "Inter",
  },
  filtersBox: {
    position: "absolute",
    top: "4rem",
    left: 0,
    background: "white",
    color: "black",
    fontSize: "0.9rem",
    fontWeight: 500,
    borderRadius: "1.125rem",
    boxShadow: "0px 0px 0.5rem rgba(0,0,0,0.1)",
    padding: "0.5rem",
    zIndex: 99,
  },
  filtersItem: {
    padding: "0.4rem 0.8rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    "&:hover": {
      background: "#f7f7f7",
    },
    "& input": {
      margin: 0,
      marginRight: "0.9rem",
      marginTop: "2px",
    },
  },
}

export default styles
