import { Button, Paper, Typography, Box, useTheme } from "@mui/material"
// import { DeleteMatIcon, EditMatIcon, LockIcon, MailIcon, NotificationIcon, PhoneIcon, WorkMatIcon } from '../../assets/Icons/Icons';

export const ProfileView = ({ editProfile, openModal, loading, profile }) => {
  const theme = useTheme()
  // const Data = ({ Icon, value }) => {
  //   return (
  //     <div className='row' style={{ margin: '1rem 0' }}>
  //       {loading ? <div variant='circular' height='3rem' width='3rem'></div> : <Icon color='secondary' sx={{ mr: '1rem' }} />}
  //       &nbsp;
  //       {loading ? (
  //         <div sx={{ ml: '1rem' }} variant='text'>
  //           <Typography>This is just some dummy text to </Typography>
  //         </div>
  //       ) : (
  //         <Typography>{value}</Typography>
  //       )}
  //     </div>
  //   );
  // };

  const Skelet = () => (
    <>
      <Box className="row">
        <div variant="circular" />
        <div variant="rectangular" />
      </Box>
    </>
  )

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          margin: { md: "10rem auto 0 auto", xs: "3rem auto 0 auto" },
          padding: "5rem 4rem",
        }}
      >
        <div className="row w-100" style={{ justifyContent: "center" }}>
          {loading ? (
            <div variant="text" width="90%">
              <Typography variant="h1">.</Typography>
            </div>
          ) : (
            <Typography variant="h1" color="primary">
              {profile.name}
            </Typography>
          )}
        </div>

        <div className="column w-100" style={{ marginTop: "5rem" }}>
          {/* <Data Icon={PhoneIcon} value={profile.phone} /> */}

          {/* <Data Icon={MailIcon} value={profile.email} /> */}

          {/* <Data Icon={WorkMatIcon} value={profile.designation} /> */}

          {/* <Data Icon={LockIcon} value='***********' /> */}
          {/* <Data
            // Icon={NotificationIcon}
            value={
              profile.pushNotifications && profile.emailNotifications
                ? 'Push and Email Notifications'
                : profile.pushNotifications
                ? 'Push Notifications'
                : 'Email Notifications'
            }
          /> */}
        </div>
      </Paper>
      <div
        className="row w-100"
        style={{ marginTop: "4rem", justifyContent: "space-between" }}
      >
        <Button
          onClick={openModal}
          color="primary"
          variant="outlined"
          style={{ width: "50%" }}
        >
          {/* <LogoutIcon fontSize="small" sx={{ mr: '0.5rem' }} /> */}
          Logout
        </Button>
        <Button
          onClick={editProfile}
          color="primary"
          variant="contained"
          style={{ width: "40%" }}
        >
          {/* <EditMatIcon sx={{ mr: '0.5rem' }} fontSize='small' /> */}
          Edit profile
        </Button>
      </div>
    </>
  )
}
