import { getHeaders, request } from "./common"

export const createStaff = (data = {}) => {
  return request({
    url: `/staff`,
    method: "PUT",
    data,
    headers: getHeaders(),
  })
}

export const getAllStaff = (data = {}) => {
  return request({
    url: `/staff`,
    method: "POST",
    data,
    headers: getHeaders(),
  })
}

export const serachStaff = (data = {}) => {
  return request({
    url: `/staff/search`,
    method: "POST",
    data,
    headers: getHeaders(),
  })
}

export const createTicket = (data = {}) => {
  return request({
    url: `/ticket`,
    method: "PUT",
    data,
    headers: getHeaders(),
  })
}

export const getAllTickets = (data = {}) => {
  return request({
    url: `/ticket`,
    method: "POST",
    data,
    headers: getHeaders(),
  })
}

export const getTicket = (id) => {
  return request({
    url: `/ticket/${id}`,
    method: "GET",
    headers: getHeaders(),
  })
}

export const editTicket = (id, data) => {
  return request({
    url: `/ticket/${id}`,
    method: "PATCH",
    data,
    headers: getHeaders(),
  })
}
