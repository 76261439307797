import { Button, MenuItem, Select, Stack, TextField } from "@mui/material"
import React from "react"
import { createTicket } from "../api/new-project"

const AddTicket = () => {
  const create = (e) => {
    e.preventDefault()
    const body = {
      camera: "62bddf181e56d8ed7a0c8626",
      detectionType: e.target.detectionType.value,
      image: e.target.image.value,
      address: e.target.address.value,
      latitude: parseFloat(e.target.latitude.value),
      longitude: parseFloat(e.target.longitude.value),
    }

    createTicket(body)
  }
  return (
    <div>
      <form onSubmit={create}>
        <Stack spacing={2} sx={{ width: "40%", mx: "auto" }}>
          <TextField name="image" label="image" />
          <Select name="detectionType" label="detectionType">
            <MenuItem value="Graffiti">Graffiti</MenuItem>
            <MenuItem value="FadedSignage">FadedSignage</MenuItem>
            <MenuItem value="Potholes">Potholes</MenuItem>
            <MenuItem value="Garbage">Garbage</MenuItem>
            <MenuItem value="ConstructionRoad">ConstructionRoad</MenuItem>
            <MenuItem value="BrokenSignage">BrokenSignage</MenuItem>
            <MenuItem value="BadStreetlight">BadStreetlight</MenuItem>
            <MenuItem value="BadBillboard">BadBillboard</MenuItem>
            <MenuItem value="SandOnRoad">SandOnRoad</MenuItem>
            <MenuItem value="ClutterSidewalk">ClutterSidewalk</MenuItem>
            <MenuItem value="UnkeptFacade">UnkeptFacade</MenuItem>
          </Select>
          <TextField name="address" label="address" />
          <TextField name="latitude" type="number" label="latitude" />
          <TextField name="longitude" type="number" label="longitude" />
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </div>
  )
}

export default AddTicket
