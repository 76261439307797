import UserPool from "./user-pool"
import _axios from "axios"
import config from "../config"

export const axios = _axios.create({ baseURL: config.baseApiURL })

export const getHeaders = () => {
  const user = UserPool.getCurrentUser()
  let token = null
  if (user) {
    user.getSession((err, session) => {
      token = session.idToken?.jwtToken
    })
  }
  console.log(token)
  return {
    Authorization: `Bearer ${token}`,
    // 'time-zone': timeZone,
  }
}

export const request = async function (options) {
  // success handler
  const onSuccess = function (response) {
    const data = response.data.data || {}
    const message = response.data.message || ""
    return { data, message }
  }

  // error handler
  const onError = function (error) {
    return Promise.reject(error.response)
  }

  // adding success and error handlers to axios request
  return axios(options).then(onSuccess).catch(onError)
}
