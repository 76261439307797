import React from "react"
import {
  Card,
  CardContent,
  Typography,
  Toolbar,
  createTheme,
  ThemeProvider,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ArrowUp from "../assets_icons/ArrowUp.svg"
import ArrowDown from "../assets_icons/ArrowDown.svg"
import theme from "../style/theme"

const theme2 = createTheme(theme, {
  shape: {
    borderRadius: 24,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles({
  crdCnt: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "10rem",
  },
  card: {
    boxShadow: " 0px 0px 60px -25px rgba(0,0,0,0.3) !important",
    width: "22vw",
    padding: "0.3rem",
    [theme2.breakpoints.down("sm")]: {
      width: "42vw",
      marginLeft: 12,
      marginRight: 12,
      marginBottom: 24,
    },
    [theme2.breakpoints.down("xs")]: {
      width: "90vw",
      marginLeft: 12,
      marginRight: 12,
      marginBottom: 24,
    },
  },
  headingLeft: {
    fontWeight: "600 !important",
    fontSize: "20px !important",
    [theme2.breakpoints.between("md", "lg")]: {
      fontSize: "1.3vw",
      width: "75%",
    },
    [theme2.breakpoints.between("sm", "md")]: {
      fontSize: "1.5vw",
      width: "75%",
    },
    [theme2.breakpoints.between("xs", "sm")]: {
      fontSize: "2vw",
      width: "75%",
    },
    [theme2.breakpoints.down("xs")]: {
      fontSize: "3.5vw",
      width: "75%",
    },
    width: "75%",
  },
  btm: {
    fontWeight: "600 !important",
    fontSize: "3rem !important",
    flexGrow: 1,
    [theme2.breakpoints.between("sm", "md")]: {
      fontSize: "2.4rem",
    },
  },
  btmRgt: {
    fontWeight: "600 !important",
    fontSize: "1.5rem !important",
    [theme2.breakpoints.between("sm", "md")]: {
      fontSize: "1.2rem",
    },
  },
  toolbarFtr: {
    padding: "1.5rem 0 0 0 !important",
    height: 60,
  },
  imgT: {
    [theme2.breakpoints.between("sm", "md")]: {
      width: 32,
      verticalAlign: "top",
      height: "2.25rem",
    },
    verticalAlign: "top",
    height: "2.25rem",
  },
  imgB: {
    width: 21,
    height: 21,
    [theme2.breakpoints.between("sm", "md")]: {
      width: 19,
      height: 19,
    },
  },
  bar: {
    display: "flex",
    justifyContent: "space-between",
  },
})

function CardLayout({
  label,
  count,
  change,
  card,
  countHr,
  countMin,
  cardType,
}) {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme2}>
      <Card className={classes.card}>
        <CardContent className={classes.crdCnt}>
          <div className={classes.bar}>
            <Typography className={classes.headingLeft}>{label}</Typography>
            <img className={classes.imgT} src={card} alt="card-1" />
          </div>
          <Toolbar className={classes.toolbarFtr}>
            {cardType === "minEmpty" ? (
              <Typography className={classes.btm} color="primary">
                {countHr === 0 ? (
                  <>
                    {countMin}
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: 600,
                      }}
                    >
                      min
                    </span>
                  </>
                ) : (
                  <>
                    {countHr}
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: 600,
                      }}
                    >
                      hr
                    </span>
                    {countMin}
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: 600,
                      }}
                    >
                      min
                    </span>
                  </>
                )}
              </Typography>
            ) : (
              <Typography className={classes.btm} color="primary">
                {count}
              </Typography>
            )}
            <div className={classes.footer}>
              {change > 0 ? (
                <>
                  <img className={classes.imgB} src={ArrowUp} alt="arrow" />
                  <Typography className={classes.btmRgt}>{change}%</Typography>
                </>
              ) : (
                <>
                  <img className={classes.imgB} src={ArrowDown} alt="arrow" />
                  <Typography className={classes.btmRgt}>{change}%</Typography>
                </>
              )}
            </div>
          </Toolbar>
        </CardContent>
      </Card>
    </ThemeProvider>
  )
}

export default CardLayout
