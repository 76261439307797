import React, { useState, useEffect } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { CssBaseline, ThemeProvider } from "@mui/material"
import Navbar from "./components/Navbar"
import CameraDetails from "./pages/camera-details"
import CameraFeed from "./pages/camera-feed"
import Home from "./pages/home"
import { Profile } from "./pages/profile"
import { LoginPage } from "./pages/login-page"
import { Amplify, Auth } from "aws-amplify"
import { SocketContextWrapper } from "./contexts/socket"
import theme from "./style/theme"
import config from "./config"
import UserContext from "./contexts/user-context"
import Location from "./pages/locations"
import Menu, { menuItems } from "./pages/menu"
import Tickets from "./pages/tickets"
import Ticket from "./pages/ticket"
import Management from "./pages/management"
import AddTicket from "./pages/add-ticket"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
})

function App() {
  const [user, setUser] = useState(null)
  const [selectedPage, setSelectedPage] = useState(menuItems[0])

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        if (currentUser !== null) {
          setUser(currentUser.attributes)
        }
      } catch (err) {
        console.error(err)
      }
    }
    verifyUser().catch(console.error)
  }, [])

  return (
    <div>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <UserContext.Provider value={{ user, setUser }}>
                <SocketContextWrapper>
                  <div style={{ margin: "0 2.5rem" }}>
                    {user && <Navbar selectedPage={selectedPage} />}
                    {!user ? (
                      <Routes>
                        <Route path="/" element={<LoginPage />} />
                        {/* <Route path="*" element={<Navigate to="/" replace />}/> */}
                      </Routes>
                    ) : (
                      <Routes>
                        <Route
                          path="/menu"
                          element={<Menu setSelectedPage={setSelectedPage} />}
                        />
                        <Route path="/dashboard" element={<Home />} />
                        <Route path="/camera" element={<CameraFeed />} />
                        <Route path="/tickets" element={<Tickets />} />
                        <Route path="/tickets/:id" element={<Ticket />} />
                        <Route path="/management" element={<Management />} />
                        <Route
                          path="/camera/view/:id"
                          element={<CameraDetails />}
                        />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/locations" element={<Location />} />
                        <Route
                          path="/"
                          element={<Navigate to="/dashboard" replace />}
                        />
                        <Route path="/add-ticket" element={<AddTicket />} />
                      </Routes>
                    )}
                  </div>
                </SocketContextWrapper>
              </UserContext.Provider>
            </CssBaseline>
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
