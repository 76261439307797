import { Button } from '@mui/material';
import React from 'react';
import { ClockIcon } from '../../assets_icons/Icons';
import { IconInput } from '../IconInput';

export default function StepTwo({ setStep }) {
  const [openingTime, setOpeningTime] = React.useState('');
  const [closingTime, setClosingTime] = React.useState('');

  const iconProps = {
    fontSize: 'large',
    sx: {
      color: '#449A68',
    },
  };

  return (
    <div>
      <div>
        <div
          style={{
            dsiplay: 'flex',
            flexDirection: 'column',
            marginTop: '2rem',
          }}
        >
          <label style={{ marginBottom: '5px', display: 'block' }}>Opening Time</label>
          <IconInput
            value={openingTime}
            onChange={(e) => setOpeningTime(e.target.value)}
            required
            type='time'
            placeholder='Opening Time'
            Icon={<ClockIcon {...iconProps} />}
            fullWidth
          />
          <label style={{ marginBottom: '5px', display: 'block' }}>Closing Time</label>
          <IconInput
            value={closingTime}
            onChange={(e) => setClosingTime(e.target.value)}
            required
            type='time'
            placeholder='Closing Time'
            Icon={<ClockIcon {...iconProps} />}
            fullWidth
          />
        </div>

        <div className='container-column' style={{ marginTop: '0rem' }}>
          <Button
            style={{
              fontWeight: 700,
              fontSize: 20,
              width: '100%',
              marginTop: '1rem',
            }}
            color='primary'
            onClick={() => setStep((s) => s + 1)}
            variant='contained'
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
