import { Grid, Paper, Typography } from "@mui/material"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { getCameraById } from "../api/camera"
import { useState, useEffect, useRef } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"

const CameraDetails = () => {
  const params = useParams()
  const cameraId = params.id
  const {
    data: { data: cameraDetails = {} } = { data: { cameraDetails: {} } },
  } = useQuery("getCameraById", () => getCameraById(cameraId))

  const data = [
    { key: "Location", value: cameraDetails.location },
    { key: "Status", value: cameraDetails.status },
    { key: "Type", value: cameraDetails.detectionType },
    { key: "Manufacturer", value: cameraDetails.manufacturer },
  ]

  const connectionData = [
    { key: "IP Address", value: cameraDetails.ipAddress },
    { key: "Port", value: cameraDetails.port },
    { key: "Type", value: cameraDetails.inputType },
    { key: "Stream Url", value: cameraDetails.streamUrl },
  ]

  const videoRef = useRef(null)
  const [player, setPlayer] = useState()

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!player) {
      const videoElement = videoRef.current
      if (!videoElement) return

      setPlayer(
        videojs(videoElement, {}, () => {
          console.log("player is ready")
        })
      )
    }
  }, [videoRef])

  return (
    <div style={{ width: "100%" }}>
      <Grid container style={{ marginBottom: "2rem" }}>
        <Grid item xs={6}>
          <Typography variant="h3" style={{ marginBottom: "1rem" }}>
            Camera Details
          </Typography>
          {/* <Typography variant='p'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Non quia beatae voluptatem rem, numquam vero quae? Quae, nemo? Neque
            incidunt cumque, deleniti dolorum optio quaerat laudantium vel sint ut amet aperiam expedita.
          </Typography> */}
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Paper elevation={0} style={{ padding: "1rem" }}>
            <Typography variant="h5" style={{ marginBottom: "0.5rem" }}>
              {cameraDetails.name} - Information
            </Typography>
            <table>
              <tbody>
                {data.map((item, index) => (
                  <tr>
                    <td style={{ padding: "0.5rem 0" }}>
                      <Typography variant="p" style={{ marginRight: "2rem" }}>
                        {item.key}
                      </Typography>
                    </td>
                    <td style={{ padding: "0.5rem 0" }}>
                      <Typography variant="p">{item.value} </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <Typography variant='h5' style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
              Alert Settings
            </Typography>
            <Typography variant='p'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, delectus.</Typography>
            <br />
            <Typography variant='p'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, delectus.</Typography> */}
            <br />
            <Typography
              variant="h5"
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            >
              Connection Settings
            </Typography>
            <table>
              <tbody>
                {connectionData.map((item, index) => (
                  <tr>
                    <td style={{ padding: "0.5rem 0" }}>
                      <Typography variant="p" style={{ marginRight: "2rem" }}>
                        {item.key}
                      </Typography>
                    </td>
                    <td style={{ padding: "0.5rem 0" }}>
                      <Typography variant="p">{item.value} </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <video
            className="video-js"
            ref={videoRef}
            controls
            autoPlay
            style={{ width: "100%", height: "auto" }}
          >
            <source
              src={cameraDetails.streamUrl}
              type="application/x-mpegURL"
            />
          </video>
        </Grid>
      </Grid>
    </div>
  )
}

export default CameraDetails
