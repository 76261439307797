import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
} from "@mui/material"
import { useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded"
import { addNewView, editView, getCamerasByLocation } from "../api/camera"
import { searchLocation } from "../api"
import { useEffect } from "react"

const columns = [
  { field: "id", headerName: "ID" },
  {
    field: "location",
    headerName: "Location",
  },
  {
    field: "name",
    headerName: "Name",
  },
  {
    field: "detectionType",
    headerName: "Detection type",
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",
  },
  {
    field: "inputType",
    headerName: "Input type",
  },
  {
    field: "ipAddress",
    headerName: "IP Address",
  },
  {
    field: "port",
    headerName: "Port",
  },
  {
    field: "username",
    headerName: "Username",
  },
  {
    field: "password",
    headerName: "Password",
  },
  {
    field: "channel",
    headerName: "Channel",
  },
  {
    field: "alertConfig",
    headerName: "Alert Config",
  },
]

const AddCameraView = ({ open, close, edit, viewData, refresh }) => {
  const [selectedCameras, setSelectedCameras] = useState([])
  const [isDefaultView, setIsDefaultView] = useState(false)
  const [location, setLocation] = useState({})
  const [viewName, setViewName] = useState("")
  const [locations, setLocations] = useState([])
  const [cameras, setCameras] = useState([])

  const fetchCameras = async (locationId) => {
    const { data = [] } = await getCamerasByLocation(locationId)
    setCameras(data)
  }

  useEffect(() => {
    handleLocationChange("")
    if (edit && viewData) {
      setSelectedCameras(viewData.cameras)
      setViewName(viewData.name)
      setIsDefaultView(viewData.default)
      setLocation(viewData.location)
      fetchCameras(viewData.location.id)
    }
  }, [edit, viewData])

  const handleLocationChange = async (val) => {
    const { data = [] } = await searchLocation(val)
    setLocations(data)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      location: location?.id || "",
      name: viewName,
      cameras: selectedCameras.map((camera) => camera.id),
      default: isDefaultView,
    }
    if (edit)
      editView(viewData.id, data).then(() => {
        refresh()
        close()
      })
    else addNewView(data).then(close)
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xl"
      PaperProps={{ style: { width: "80%", paddingBottom: "1rem" } }}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: 700,
            textAlign: "center",
          }}
          variant="h5"
          color="primary"
        >
          {edit ? "Edit" : "Add New"} View
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Box
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Autocomplete
                options={locations}
                getOptionLabel={(option) => option?.name || ""}
                value={location}
                onChange={(_, newVal) => {
                  setLocation(newVal)
                  if (newVal?.id) fetchCameras(newVal.id)
                }}
                onInputChange={(e, newInputValue) => {
                  handleLocationChange(newInputValue)
                }}
                sx={{ width: 300, pt: "5px" }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Location" />
                )}
              />
            </Box>

            <Box style={{ marginTop: "2rem" }}>
              <div style={{ height: 400, width: "100%" }}>
                {cameras?.length ? (
                  <DataGrid
                    rows={cameras}
                    columns={columns}
                    selectionModel={selectedCameras.map((s) => s.id)}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids)
                      const selectedRowData = cameras.filter((row) =>
                        selectedIDs.has(row.id)
                      )
                      setSelectedCameras(selectedRowData)
                    }}
                    pageSize={5}
                    checkboxSelection
                    disableSelectionOnClick
                  />
                ) : (
                  <Typography sx={{ textAlign: "center" }}>
                    No cameras found <br /> (Try changing the location)
                  </Typography>
                )}
              </div>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={4}
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <TextField
                label="View Name"
                variant="outlined"
                fullWidth
                value={viewName}
                onChange={(e) => setViewName(e.target.value)}
                sx={{ mt: "5px" }}
              />

              <FormControlLabel
                control={<Checkbox name="default" color="primary" />}
                label="Set this view as default"
                style={{ marginTop: "1rem" }}
                checked={isDefaultView}
                onChange={(e) => setIsDefaultView(e.target.checked)}
              />
              <Typography style={{ marginTop: "1rem" }}>
                {selectedCameras.length} Camera(s) Selected
              </Typography>

              <List dense>
                {selectedCameras.map((c) => (
                  <ListItem>
                    <ListItemText primary={c.name} />
                    <IconButton
                      onClick={() =>
                        setSelectedCameras((s) =>
                          s.filter((s) => s.id !== c.id)
                        )
                      }
                      color="primary"
                    >
                      <DeleteRoundedIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default AddCameraView
