import React, { useState } from "react"
import { IconInput } from "../IconInput"
import { PasswordInput } from "../PasswordInput"
import {
  Link,
  Button,
  CircularProgress,
  Typography,
  useTheme,
  Box,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@mui/material"
import {
  MailIcon,
  PersonIcon,
  PhoneIcon,
  WorkMatIcon,
} from "../../assets_icons/Icons"
import PersonRoundedIcon from "@mui/icons-material/PersonRounded"
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded"
import EmailRoundedIcon from "@mui/icons-material/EmailRounded"
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded"
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded"
import Snackbar from "@mui/material/Snackbar"
import KeyboardArrowDownRounded from "@mui/icons-material/KeyboardArrowDownRounded"
import { timeZoneList } from "../../utilities/constants"

export default function StepOne({
  setToLogin,
  handleSignUp,
  formData,
  setFormData,
  loading,
  toast,
}) {
  const handleChange = (e) =>
    setFormData((f) => ({ ...f, [e.target.name]: e.target.value }))

  const iconProps = {
    fontSize: "large",
    sx: {
      color: "#449A68",
    },
  }

  const validate = () => {
    if (formData.organizationName === "")
      toast("Organisation name can't be empty!")
    else if (
      !/^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/.test(
        formData.phone
      )
    )
      toast("Invalid phone number!")
    else if (!/^[A-Z0-9]{3}$/.test(formData.stockSymbol)) {
      toast(
        "Invalid stock symbol! Stock symbol should be exactly 3 characters long, made of capital letters and numbers "
      )
    } else handleSignUp()
  }

  return (
    <>
      <div>
        <div
          style={{
            dsiplay: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <IconInput
            value={formData.organizationName}
            onChange={handleChange}
            name="organizationName"
            required
            placeholder="Organization Name"
            Icon={<PersonRoundedIcon {...iconProps} />}
            fullWidth
          />

          <IconInput
            value={formData.phone}
            onChange={handleChange}
            name="phone"
            type="tel"
            placeholder="Contact Number"
            Icon={<PhoneRoundedIcon {...iconProps} />}
            fullWidth
          />
          <FormControl
            fullWidth
            style={{ marginBottom: "1rem", marginTop: "-6px" }}
          >
            <InputLabel>Time Zone</InputLabel>
            <Select
              startAdornment={<AccessTimeRoundedIcon {...iconProps} />}
              value={formData.timeZone}
              label="Time Zone"
              name="timeZone"
              onChange={handleChange}
            >
              {timeZoneList.map((t) => (
                <MenuItem value={t}>{t}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <IconInput
            value={formData.timeZone}
            onChange={handleChange}
            name='timeZone'
            placeholder='Time Zone'
            Icon={<AccessTimeRoundedIcon {...iconProps} />}
            fullWidth
          /> */}

          <IconInput
            value={formData.stockSymbol}
            onChange={handleChange}
            name="stockSymbol"
            placeholder="Code/Stock Symbol"
            Icon={<BusinessCenterRoundedIcon {...iconProps} />}
            fullWidth
          />
          <IconInput
            value={formData.organizationEmail}
            onChange={handleChange}
            name="organizationEmail"
            required
            placeholder="Organization Email"
            Icon={<EmailRoundedIcon {...iconProps} />}
            fullWidth
          />
          <PasswordInput
            value={formData.password}
            onChange={handleChange}
            name="password"
            fullWidth
          />
        </div>

        <div className="container-column" style={{ marginTop: "0rem" }}>
          <Button
            style={{
              fontWeight: 700,
              fontSize: 20,
              width: "100%",
              marginTop: "1rem",
            }}
            color="primary"
            onClick={validate}
            variant="contained"
          >
            {loading ? (
              <CircularProgress size={"1.4rem"} color="inherit" />
            ) : (
              "Register"
            )}
          </Button>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              gap: "0.3rem",
              alignItems: "center",
            }}
          >
            <Typography>Already a User?</Typography>
            <Link
              onClick={setToLogin}
              style={{
                fontWeight: 700,
                fontSize: 18,
                textDecoration: 'none',
                cursor: "pointer",
              }}
              color="primary"
            >
              Sign in Now
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
