import Box from "@mui/material/Box"
import { makeStyles } from "@mui/styles"
import React, { useEffect } from "react"
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  Line,
  Tooltip,
} from "recharts"
import { useDispatch, useSelector } from "react-redux"
import { changeGraphData } from "../redux/graphData"
import { getFoodGraphData, getGraphData, getPeopleGraphData } from "../api"
import _ from "lodash"
import { addAllDate } from "../redux/allDates"
import { sortByMonthHelper, sortByWeekDayHelper } from "../utilities/functions"
import theme from "../style/theme"

const colors = ["#449A68", "#9FE2BE", "#9FF5BE"]

const useStyles = makeStyles({
  box: {
    height: "70vh",
    margin: "1rem 0",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-evenly",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
  },
  lines: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    position: "relative",
    marginLeft: "auto",
  },
})

const Graph = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const presentDate = useSelector((state) => state.presentDate)
  const type = useSelector((state) => state.typeSelected)
  const data = useSelector((state) => state.graphData)
  const storesData = useSelector((state) => state.stores)
  const currentTimingType = useSelector((state) => state.typeSelected.type)
  const dataType = useSelector((state) => state.dataType.type)
  const dates = useSelector((state) => state.dateRange)
  const allRanges = useSelector((state) => state.allDates)

  useEffect(() => {
    fetchGraphData()
  }, [
    presentDate.from,
    presentDate.to,
    type.type,
    storesData.selectedLocations,
    storesData.selectedCameras,
    storesData.showFoodStats,
  ])

  const fetchGraphData = async () => {
    if (!presentDate.from || !presentDate.to) return
    const locationIds =
      storesData.selectedLocations.map((l) => l.id) || undefined
    const cameraIds =
      storesData.selectedCameras.length > 0
        ? storesData.selectedCameras
        : undefined
    let resData = null
    if (storesData.showFoodStats) {
      resData = await getFoodGraphData(
        presentDate.from,
        presentDate.to,
        1 || type.type,
        locationIds,
        cameraIds
      )
    } else {
      resData = await getPeopleGraphData(
        presentDate.from,
        presentDate.to,
        1 || type.type,
        locationIds,
        cameraIds
      )
    }
    let [hour, allHours] = handleGraphDataResponse(
      "hour",
      data.hour,
      resData.data.hour
    )
    let [weekDay, allWeekDays] = handleGraphDataResponse(
      "weekDay",
      data.weekDay,
      resData.data.weekDay
    )
    let [week, allWeeks] = handleGraphDataResponse(
      "week",
      data.week,
      resData.data.week
    )
    let [date, allDates] = handleGraphDataResponse(
      "date",
      data.date,
      resData.data.date
    )
    let [month, allMonths] = handleGraphDataResponse(
      "month",
      data.month,
      resData.data.month
    )
    dispatch(
      addAllDate({
        hour: allHours,
        weekDay: allWeekDays,
        week: allWeeks,
        date: allDates,
        month: allMonths,
      })
    )
    dispatch(
      changeGraphData({
        hour,
        weekDay,
        date,
        week,
        month,
      })
    )
  }

  const handleGraphDataResponse = (timingType, actualArr, resArr) => {
    resArr = resArr ? resArr : []
    resArr.totalVisits = resArr.totalVisits ? resArr.totalVisits : []
    resArr.minEmpty = resArr.minEmpty ? resArr.minEmpty : []
    resArr.maxVisits = resArr.maxVisits ? resArr.maxVisits : []
    resArr.averageHourlyVisits = resArr.averageHourlyVisits
      ? resArr.averageHourlyVisits
      : []
    resArr.averageDailyVisits = resArr.averageDailyVisits
      ? resArr.averageDailyVisits
      : []
    let newArr = {}
    const keys = [
      "totalVisits",
      "minEmpty",
      "averageHourlyVisits",
      "averageDailyVisits",
      "maxVisits",
    ]
    keys.map((key) => {
      if (actualArr[key].length === 0) {
        newArr[key] = resArr[key].map((x) => {
          x["value" + presentDate.id] = x.value
          delete x.value
          return x
        })
      } else {
        newArr[key] = _.cloneDeep(actualArr[key])

        for (let i = 0; i < newArr[key].length; i++) {
          delete newArr[key][i]["value" + presentDate.id]
          if (!Object.keys(newArr[key][i]).find((x) => x.includes("value"))) {
            newArr[key].splice(i, 1)
            i--
          }
        }

        for (let i = 0; i < newArr[key].length; i++) {
          let currEl = newArr[key][i]
          for (let j = 0; j < resArr[key].length; j++) {
            let resEl = resArr[key][j]
            if (currEl.name === resEl.name) {
              currEl["value" + presentDate.id] = resEl.value
              break
            }
          }
        }
        for (let i = 0; i < resArr[key].length; i++) {
          if (newArr[key].find((x) => x.name === resArr[key][i].name)) continue
          resArr[key][i]["value" + presentDate.id] = resArr[key][i].value
          delete resArr[key][i].value
          newArr[key].push(resArr[key][i])
        }
      }
      let full_range = {}
      allRanges[timingType].map((x) => {
        full_range[x] = { name: x, range: "", ["value" + presentDate.id]: null }
      })
      newArr[key].map((x) => {
        full_range[x.name] = x
      })
      newArr[key] = Object.values(full_range).map((x) => x)
      newArr[key].sort((a, b) => {
        if (timingType === "date") return new Date(a.name) - new Date(b.name)
        if (timingType === "week") return ("" + a.name).localeCompare(b.name)
        if (timingType === "month") return sortByMonthHelper(a, b)
        if (timingType === "weekDay") return sortByWeekDayHelper(a, b)
        return a.name - b.name
      })
    })
    return [newArr, newArr["totalVisits"].map((x) => x.name)] // Using any one of the keys to get the merged updated date ranges
  }

  const key = type.type

  return (
    <div>
      <Box className={classes.box} borderRadius={2}>
        <ResponsiveContainer
          width={data[key][dataType].length > 156 ? "150%" : "100%"}
          height="90%"
          className={classes.container}
        >
          <ComposedChart
            // barGap = {40}
            data={
              (dataType === "totalVisits" && data[key].totalVisits) ||
              (dataType === "minEmpty" && data[key].minEmpty) ||
              (dataType === "maxVisits" && data[key].maxVisits) ||
              (dataType === "averageHourlyVisits" &&
                data[key].averageHourlyVisits) ||
              (dataType === "averageDailyVisits" &&
                data[key].averageDailyVisits)
            }
            margin={{
              right: 20,
              left: 20,
              top: 10,
              bottom: 10,
            }}
            barCategoryGap={30}
            barSize={50}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              interval={allRanges[currentTimingType].length > 100 ? 5 : 0}
              ticks={allRanges[currentTimingType]}
              angle={-45}
              type="category"
              height={65}
              dy={25}
              padding={{ left: 100, right: 100 }}
            />
            <YAxis axisLine={false} />
            <Tooltip />
            {type.type === "weekDay" || type.type === "hour"
              ? dates.map((item) => (
                <Bar
                  key={item.id}
                  dataKey={"value" + item.id}
                  fill={colors[dates.indexOf(item) % colors.length]}
                  radius={[12, 12, 0, 0]}
                />
              ))
              : dates.map((item) => (
                <Line
                  key={item.id}
                  type="monotone"
                  fill={colors[dates.indexOf(item) % colors.length]}
                  dataKey={"value" + item.id}
                  stroke={colors[dates.indexOf(item) % colors.length]}
                />
              ))}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </div>
  )
}

export default Graph
