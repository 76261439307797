import { request, getHeaders } from "./common"

export const createEntity = (data) => {
  return request({
    url: "/entity",
    method: "PUT",
    data,
    headers: getHeaders(),
  })
}

export const updateEntity = (id, data) => {
  return request({
    url: `/entity/${id}`,
    method: "PATCH",
    data,
    headers: getHeaders(),
  })
}

export const getEntityById = (id) => {
  return request({
    url: `/entity/${id}`,
    method: "GET",
    headers: getHeaders(),
  })
}

export const getAllStores = (entityId) => {
  // return axios.get(`/entity/stores?name=${name}`);
  return request({
    url: `/entity/stores`,
    method: "POST",
    data: {
      entityId,
    },
    headers: getHeaders(),
  })
}

export const createLocation = (data) => {
  return request({
    url: "/location",
    method: "PUT",
    data,
    headers: getHeaders(),
  })
}

export const updateLocation = (id, data) => {
  return request({
    url: `/location/${id}`,
    method: "PATCH",
    data,
    headers: getHeaders(),
  })
}

export const deleteLocation = (id) => {
  return request({
    url: `/location/${id}`,
    method: "DELETE",
    headers: getHeaders(),
  })
}

export const getFoodCardsData = (
  startDate,
  endDate,
  showPrediction,
  locationIds,
  cameraIds
) => {
  return request({
    url: `/info/food`,
    method: "POST",
    data: {
      startDate,
      endDate,
      showPrediction,
      locationIds,
      cameraIds,
    },
    headers: getHeaders(),
  })
}

export const getPeopleCardsData = (
  startDate,
  endDate,
  showPrediction,
  locationIds,
  cameraIds
) => {
  return request({
    url: `/info/people`,
    method: "POST",
    data: {
      startDate,
      endDate,
      showPrediction,
      locationIds,
      cameraIds,
    },
    headers: getHeaders(),
  })
}

export const getFoodGraphData = (
  startDate,
  endDate,
  detectionType,
  locationIds,
  cameraIds
) => {
  return request({
    url: `/summary/food`,
    method: "POST",
    data: {
      startDate,
      endDate,
      detectionType,
      locationIds,
      cameraIds,
    },
    headers: getHeaders(),
  })
}

export const getPeopleGraphData = (
  startDate,
  endDate,
  detectionType,
  locationIds,
  cameraIds
) => {
  return request({
    url: `/summary/people`,
    method: "POST",
    data: {
      startDate,
      endDate,
      detectionType,
      locationIds,
      cameraIds,
    },
    headers: getHeaders(),
  })
}

export const searchLocation = (name) => {
  return request({
    url: `/location/search`,
    method: "POST",
    data: { name },
    headers: getHeaders(),
  })
}
