import { getHeaders, request } from "./common"

export const getAllCameras = () => {
  return request({
    url: "/camera",
    method: "POST",
    data: {},
    headers: getHeaders(),
  })
}

export const getCamerasByLocation = (locationId) => {
  return request({
    url: "/camera/search",
    method: "POST",
    data: {
      locationId,
    },
    headers: getHeaders(),
  })
}

export const getCameraById = (id) => {
  return request({
    url: `/camera/${id}`,
    method: "GET",
    headers: getHeaders(),
  })
}

export const editCamera = (id, data) => {
  return request({
    url: `/camera/${id}`,
    method: "PATCH",
    data,
    headers: getHeaders(),
  })
}

export const addNewCamera = (data) => {
  return request({
    url: `/camera`,
    method: "PUT",
    data,
    headers: getHeaders(),
  })
}

export const deleteCameraById = (id) => {
  return request({
    url: `/camera/${id}`,
    method: "DELETE",
    headers: getHeaders(),
  })
}

export const addNewView = (data) => {
  return request({
    url: "/view",
    method: "PUT",
    data,
    headers: getHeaders(),
  })
}

export const editView = (id, data) => {
  return request({
    url: `/view/${id}`,
    method: "PATCH",
    data,
    headers: getHeaders(),
  })
}

export const getView = (id) => {
  return request({
    url: `/view/${id}`,
    method: "GET",
    headers: getHeaders(),
  })
}

export const getAllViews = () => {
  return request({
    url: "/view",
    method: "POST",
    data: {
      pageNumber: 0,
      maxItems: 10,
      sortOrder: 1,
    },
    headers: getHeaders(),
  })
}
