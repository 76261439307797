import {
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { useEffect, useState } from "react"
import ReactMap from "../components/ReactMap"
import { Toggle, Toggle2 } from "../components/styled"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { serachStaff } from "../api/new-project"
import LoadingContent from "../components/LoadingContent"

const staffTypes = [
  ["All", ""],
  ["Contributor", "Contributor"],
  ["Dispatcher", "Dispatcher"],
  ["Municipality", "Municipality"],
  ["Field Employee", "FieldEmployee"],
  ["Management", "Management"],
]

const Management = () => {
  const [view, setView] = useState(0)
  const [staff, setStaff] = useState([])
  const [staffType, setStaffType] = useState(staffTypes[0][1])
  const [loading, setLoading] = useState(false)

  const fetchStaff = () => {
    setLoading(true)
    serachStaff({
      name: "",
      staffType,
    })
      .then((res) => setStaff(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchStaff()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffType])

  return (
    <Container maxWidth="lg" fullWidth sx={{ pt: 5 }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems={"center"}
        mb={3}
        spacing={3}
      >
        <Toggle
          val1={0}
          val2={1}
          label1="Assets"
          label2="Staff"
          selected={view}
          setSelected={setView}
        />
        {!!view && (
          <Toggle2
            options={staffTypes.map((t) => ({ label: t[0], val: t[1] }))}
            selected={staffType}
            setSelected={setStaffType}
          />
        )}
      </Stack>
      {view ? (
        <>
          <LoadingContent loading={loading}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staff.map((e) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{e?.name}</TableCell>
                      <TableCell>{e?.email}</TableCell>
                      <TableCell>{e?.phoneNumber}</TableCell>
                      <TableCell>{e?.staffType}</TableCell>
                      <TableCell align="right">
                        <IconButton>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </LoadingContent>
          <Stack alignItems="center" sx={{ my: 2 }}>
            <Pagination count={1} />
          </Stack>
        </>
      ) : (
        <ReactMap
          carIcon
          markers={[
            {
              latitude: 23,
              longitude: 45,
            },
            {
              latitude: 29,
              longitude: 47,
            },
            {
              latitude: 30,
              longitude: 43,
            },
          ]}
        />
      )}
    </Container>
  )
}

export default Management
