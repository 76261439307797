import {
  Avatar,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Rating,
  Stack,
} from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import CallIcon from "@mui/icons-material/Call"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useParams } from "react-router-dom"
import { editTicket, getTicket } from "../api/new-project"
import LoadingContent from "../components/LoadingContent"
import AcknowledgeModal from "../components/AcknowledgeModal"
import dayjs from "dayjs"

const Ticket = () => {
  const [showModal, setShowModal] = useState(false)
  const [ticket, setTicket] = useState({})
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [rating, setRating] = useState(0)
  const [ratingLoading, setRatingLoading] = useState(false)

  const fetchTicket = () => {
    setLoading(true)
    getTicket(id)
      .then((res) => {
        setTicket(res.data)
        if (res.data?.rating) {
          setRating(parseInt(res.data?.rating))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const steps = useMemo(
    () => [
      {
        label: "Ticket created",
        desc: "on Mon, 12 Jan, 2023",
      },
      {
        label: "In progress",
      },
      {
        label: "Resolved",
        desc:
          ticket.status !== "Created"
            ? (ticket.status === "Resolved" || ticket.status === "Reviewed"
                ? "on"
                : "by") +
              " " +
              dayjs(
                ticket.status === "Resolved" || ticket.status === "Reviewed"
                  ? ticket.actualResolution
                  : ticket.estimatedResolution
              ).format("ddd, D MMM, YYYY")
            : "",
      },
    ],
    [ticket]
  )

  const rateTicket = async () => {
    setRatingLoading(true)
    await editTicket(ticket.id, {
      ...ticket,
      status: "Reviewed",
      rating: rating.toString(),
    })
      .then(() => {
        fetchTicket()
      })
      .catch((err) => console.log(err))
      .finally(() => setRatingLoading(false))
  }

  useEffect(() => {
    fetchTicket()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth="lg" fullWidth sx={{ pt: 15, pb: 3 }}>
      <LoadingContent loading={loading}>
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <img
              src={ticket?.image}
              alt="im"
              style={{ width: "100%", borderRadius: "1.5rem" }}
            />
          </Grid>
          <Grid item xs={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <div>
                <Typography variant="h6">{ticket?.detectionType}</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontWeight: 600 }}
                >
                  {ticket?.address}
                </Typography>
              </div>
              <Chip label={ticket?.status} />
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div style={{ width: "100%" }}>
                <CardHeader
                  avatar={<Avatar>R</Avatar>}
                  action={
                    <IconButton>
                      <CallIcon />
                    </IconButton>
                  }
                  title="Jai Sharma"
                  subheader="Opposite Jain Glass house "
                />
                {/* <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ pl: "2rem" }}
                >
                  Garbage is lying on the road for last three days. Please send
                  some one to clear this asap
                </Typography> */}
              </div>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stepper
              activeStep={
                ticket.status === "Resolved" || ticket.status === "Reviewed"
                  ? 3
                  : ticket.status === "Assigned"
                  ? 2
                  : 1
              }
              orientation="vertical"
              sx={{ mb: 5 }}
            >
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      <Typography variant="body2">{step.desc}</Typography>
                    }
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {ticket.status === "Resolved" || ticket.status === "Reviewed" ? (
              <>
                <Stack alignItems={"center"} mb={2}>
                  <Rating
                    size="large"
                    value={rating}
                    onChange={(_, val) => setRating(val)}
                  />
                </Stack>

                {ticket.status !== "Reviewed" && (
                  <Button onClick={rateTicket} variant="contained" fullWidth>
                    {ratingLoading ? <CircularProgress /> : "Rate"}
                  </Button>
                )}
              </>
            ) : (
              <Button
                onClick={() => setShowModal(true)}
                variant="contained"
                fullWidth
              >
                {ticket.status !== "Assigned" ? "Assign" : "Resolve"}
              </Button>
            )}
          </Grid>
        </Grid>
        <AcknowledgeModal
          open={showModal}
          close={() => setShowModal(false)}
          ticket={ticket}
          refresh={fetchTicket}
          resolve={ticket.status === "Assigned"}
        />
      </LoadingContent>
    </Container>
  )
}

export default Ticket
