import { Box } from "@mui/material"
import React, { useState } from "react"
import { ProfileView } from "../components/ProfileView"
import { ProfileEdit } from "../components/ProfileEdit"
import DeleteModal from "../components/DeleteModal"

export const Profile = () => {
  const [edit, setEdit] = React.useState(false)
  const [deleteAccount, setDeleteAccount] = React.useState(false)
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState({})

  const editPorfile = () => {
    setEdit(true)
  }
  const cancel = () => {
    setEdit(false)
  }
  const saveProfile = async (body) => {
    setEdit(false)
    setLoading(true)
    let res = {} // await updateProfile(body);
    console.log(res)
    getProfile()
    //setLoading(false)
  }

  const openModal = () => {
    setDeleteAccount(true)
  }
  const cancelDelete = () => {
    setDeleteAccount(false)
  }

  const getProfile = async () => {
    setLoading(true)
    let _profile = {} // await getProfileDetails();
    setProfile(_profile)
    console.log(_profile)
    setLoading(false)
  }

  React.useEffect(() => {
    getProfile()
  }, [])

  return (
    <Box
      className="w-100 h-100 column"
      sx={{
        padding: {
          md: "0 35% ",
          sm: "0 20%",
          xs: "0 2%",
        },
        justifyContent: "space-between",
        margin: "auto 0",
      }}
    >
      {edit ? (
        <ProfileEdit
          profile={profile}
          saveProfile={saveProfile}
          cancel={cancel}
        />
      ) : (
        <ProfileView
          loading={loading}
          profile={profile}
          openModal={openModal}
          editProfile={editPorfile}
        />
      )}

      <DeleteModal cancel={cancelDelete} open={deleteAccount} />
    </Box>
  )
}
