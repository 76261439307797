import React, { useState } from "react"
import Optika from "../assets_icons/optika.png"
import { Link, Button, CircularProgress, Typography } from "@mui/material"
import { IconInput } from "./IconInput"
import { PasswordInput } from "./PasswordInput"
import { Box } from "@mui/material"
import PersonRoundedIcon from "@mui/icons-material/PersonRounded"
import { useNavigate } from "react-router-dom"
import { Auth } from "aws-amplify"
import UserContext from "../contexts/user-context"
import { withToast } from "./withToast"

const Login = ({ setToRegister, toast }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const { setUser } = React.useContext(UserContext)

  const navigate = useNavigate()

  const signIn = async () => {
    setLoading(true)
    try {
      let _user = await Auth.signIn(username, password)
      setUser(_user.attributes)
      _user.cacheTokens()
      _user.cacheUserData()
      setLoading(false)
    } catch (e) {
      toast(e.code)
      setLoading(false)
    }
  }

  const iconProps = {
    fontSize: "large",
    sx: {
      color: "#449A68",
    },
  }

  return (
    <Box>
      <Typography
        style={{
          fontWeight: 700,
        }}
        variant="h4"
      >
        Welcome back to
      </Typography>
      <img src={Optika} alt="optika icon" style={{ height: "4rem" }} />

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "3rem" }}
      >
        <IconInput
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          Icon={<PersonRoundedIcon {...iconProps} />}
        />
        <PasswordInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <Button
          onClick={signIn}
          style={{
            fontWeight: 700,
            fontSize: 20,
            width: "100%",
          }}
          color="primary"
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={"1.4rem"} color="inherit" />
          ) : (
            "Login"
          )}
        </Button>
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            gap: "0.3rem",
            alignItems: "center",
          }}
        >
          <Typography>Not a registered User?</Typography>
          <Link
            onClick={setToRegister}
            style={{
              fontWeight: 700,
              fontSize: 18,
              textDecoration: 'none',
              cursor: "pointer",
            }}
            color="primary"
          >
            Register Now
          </Link>
        </div>
      </div>
    </Box>
  )
}

export default withToast(Login)
