import { createSlice } from '@reduxjs/toolkit';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

const dateSlice = createSlice({
  name: 'date',
  initialState: [{
    id: 1,
    from: months[new Date().getMonth()] + ' ' + new Date().getDate() + ', ' + new Date().getFullYear(),
    to: months[new Date().getMonth()] + ' ' + new Date().getDate() + ', ' + new Date().getFullYear()
  }],
  reducers: {
    addDateRange: (state, action) => {
      const newRange = {
        id: action.payload.id,
        from: action.payload.from,
        to: action.payload.to,
      };
      const isPresent = state.find(item => item.id === action.payload.id)
      if(!isPresent){
        state.push(newRange);
      }
    },
    deleteDate: (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    },
    updateDateRange: (state, action) => {
      for(let i = 0; i<state.length; i++){
        if(state[i].id === action.payload.id){
          state[i].from = action.payload.from
          state[i].to = action.payload.to
        }
      }
    }
  },
});

export const { addDateRange, deleteDate, updateDateRange } = dateSlice.actions;
export default dateSlice.reducer;
