const styles = {
  buttonCal: {
    height: "3rem",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "0.9rem",
    backgroundColor: "#fff",
    boxShadow: " 0px 0px 60px -18px rgba(0,0,0,0.3)",
    color: "#000000",
    width: "100%",
    justifyContent: "space-between",
    "&:hover": {
      background: "white",
      boxShadow: " 0px 0px 30px -18px rgba(0,0,0,0.8)",
    },
    textTransform: "none",
  },
  icons: {
    display: "flex",
    marginRight: "0.5rem",
  },
  crossStyle: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}

export default styles
