import { createSlice } from '@reduxjs/toolkit';

const graphData = createSlice({
  name: 'graphData',
  initialState: {
    hour: {
      averageHourlyVisits: [],
      averageMonthlyResolutions: [],
      totalVisits: [],
      maxVisits: [],
      minEmpty: []
    },
    weekDay: {
      averageHourlyVisits: [],
      averageMonthlyResolutions: [],
      totalVisits: [],
      maxVisits: [],
      minEmpty: []
    },
    date: {
      averageHourlyVisits: [],
      averageMonthlyResolutions: [],
      totalVisits: [],
      maxVisits: [],
      minEmpty: []
    },
    week: {
      averageHourlyVisits: [],
      averageMonthlyResolutions: [],
      totalVisits: [],
      maxVisits: [],
      minEmpty: []
    },
    month: {
      averageHourlyVisits: [],
      averageMonthlyResolutions: [],
      totalVisits: [],
      maxVisits: [],
      minEmpty: []
    }
  },
  reducers: {
    changeGraphData: (state, action) => {
      state.hour = action.payload.hour;
      state.weekDay = action.payload.weekDay;
      state.date = action.payload.date;
      state.week = action.payload.week;
      state.month = action.payload.month;
    }
  },
});

export const { changeGraphData } = graphData.actions;
export default graphData.reducer;
