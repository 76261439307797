import { Auth } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { createEntity } from "../../api"
import UserContext from "../../contexts/user-context"
import StepOne from "./StepOne"
import StepTwo from "./StepTwo"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import StepThree from "./StepThree"
import { userTimeZone } from "../../utilities/constants"
import { withToast } from "../withToast"

const Register = ({ setToLogin, toast }) => {
  const { setUser } = React.useContext(UserContext)
  const [currentStep, setCurrentStep] = useState(0)
  const [entityFormData, setEntityFormData] = useState({
    organizationName: "",
    organizationEmail: "",
    phone: "",
    timeZone: userTimeZone === "Asia/Calcutta" ? "Asia/Kolkata" : userTimeZone,
    password: "",
    stockSymbol: "",
  })
  const [entityLocationData, setEntityLocationData] = useState({
    locationName: "",
    address: "",
  })
  const [loading, setLoading] = useState(false)

  const handleSignin = async (username, password) => {
    try {
      let _user = await Auth.signIn(
        entityFormData.organizationEmail,
        entityFormData.password
      )
      console.log("user is", _user)
      setUser(_user.attributes)
      _user.cacheTokens()
      _user.cacheUserData()
    } catch (e) {
      toast(e.message)
    }
  }

  const handleSignUp = async () => {
    setLoading(true)
    try {
      await Auth.signUp({
        username: entityFormData.organizationEmail,
        password: entityFormData.password,
        attributes: {
          name: entityFormData.organizationName,
          nickname: entityFormData.stockSymbol,
          email: entityFormData.organizationEmail,
          phone_number: entityFormData.phone,
          zoneinfo: entityFormData.timeZone,
        },
      })
      await handleSignin(
        entityFormData.organizationEmail,
        entityFormData.password
      )
      setLoading(false)
    } catch (err) {
      toast(err.message)
      setLoading(false)
    }
  }

  if (currentStep === 0)
    return (
      <StepOne
        setToLogin={setToLogin}
        formData={entityFormData}
        setFormData={setEntityFormData}
        handleSignUp={handleSignUp}
        loading={loading}
        toast={toast}
      />
    )
  if (currentStep === 1) return <StepTwo setStep={setCurrentStep} />
  if (currentStep === 2)
    return (
      <StepThree
        formData={entityLocationData}
        setFormData={setEntityLocationData}
      />
    )
  return <></>
}

export default withToast(Register)
