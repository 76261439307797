import React, { useState } from "react"
import { Typography, Menu, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Calendar2 from "./calendar"
import styles from "../style/styleApp"
import { useDispatch } from "react-redux"
import { increment } from "../redux/Ids"

const useStyles = makeStyles(styles)

function AddComparision({
  addComparison,
  setDateTemp,
  dateTemp,
  comparisonIds,
  setComparisonIds,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    addComparison()
    dispatch(increment())
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ marginRight: "auto" }}>
      <Button color="primary" className={classes.addComp} onClick={handleClick}>
        + Add comparison
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Calendar2
          type="add"
          setComparisonIds={setComparisonIds}
          comparisonIds={comparisonIds}
          setDateTemp={setDateTemp}
          dateTemp={dateTemp}
          handleClose={handleClose}
        />
      </Menu>
    </div>
  )
}

export default AddComparision
