import { createSlice } from '@reduxjs/toolkit';

const comparisionData = createSlice({
  name: 'comparisionData',
  initialState: {
    averageHourlyVisits: [],
    totalVisits: [],
    maxVisits: [],
    minEmpty: [],
  },
  reducers: {
    changeComparisionData: (state, action) => {
      const newData = {
        id: 0,
        name: action.payload.name,
        value1: action.payload.value1,
        value2: action.payload.value2,
        value3: action.payload.value3,
      };
      state.averageHourlyVisits.push(newData);
    },
  },
});

export const { changeComparisionData } = comparisionData.actions;
export default comparisionData.reducer;
