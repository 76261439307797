import { useState } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Typography,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material"
import { addNewCamera, editCamera, getCameraById } from "../api/camera"
import { searchLocation } from "../api"
import { useEffect } from "react"

const detectionTypes = ["People", "Food"]
const inputTypes = ["RTSP", "HTTP", "CSI"]

const AddCamera = ({ open, close, id }) => {
  const [inputMode, setInputMode] = useState("details")

  const toggleInputMode = () => {
    if (inputMode === "details") {
      setInputMode("rtsp_url")
      setFormData({
        ...commonInitialState,
        rtspUrl: "",
      })
    } else {
      setInputMode("details")
      setFormData({
        ...commonInitialState,
        ...initialFormState,
      })
    }
  }

  const [location, setLocation] = useState(null)
  const [locations, setLocations] = useState([])

  const handleLocationChange = async (val) => {
    const { data = [] } = await searchLocation(val)
    setLocations(data)
  }

  const commonInitialState = {
    name: "",
    detectionType: "",
    manufacturer: "",
    cameraAccess: "Public",
    location: null,
  }

  const initialFormState = {
    inputType: "",
    ipAddress: "",
    port: "",
    username: "",
    password: "",
    channel: "",
  }

  const [formData, setFormData] = useState({
    ...commonInitialState,
    ...initialFormState,
  })

  const fetchCamera = async (id) => {
    const { data = null } = await getCameraById(id)
    if (data) {
      const { alertConfig, id, status, streamUrl, ...rest } = data
      const cameraFormData = { ...rest, cameraAccess: "Public" }
      setFormData(cameraFormData)
    }
  }

  useEffect(() => {
    if (id) fetchCamera(id)
    else setFormData(initialFormState)
  }, [id])

  const handleChange = (e) =>
    setFormData((f) => ({ ...f, [e.target.name]: e.target.value }))

  const handleSubmit = (e) => {
    e.preventDefault()
    const body = {
      ...formData,
      location: location?.id ? location.id : formData.location,
    }
    if (inputMode === "details") body.port = +body.port
    if (id) editCamera(id, body).then(close)
    else addNewCamera(body).then(close)
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xl"
      PaperProps={{ style: { width: "50%" } }}
    >
      <DialogTitle>
        {" "}
        <Typography
          style={{
            fontWeight: 700,
            textAlign: "center",
          }}
          variant="h5"
          color="primary"
        >
          {id ? "Edit" : "Add New"} Camera
        </Typography>{" "}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <label style={{ marginLeft: "0.2rem", color: "#555" }}>
              Camera Name
            </label>
            <TextField
              style={{ marginTop: "0.3rem" }}
              variant="outlined"
              placeholder="Enter Camera Name"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <label style={{ marginLeft: "0.2rem", color: "#555" }}>
              Manufacturer Name
            </label>
            <TextField
              style={{ marginTop: "0.3rem" }}
              variant="outlined"
              placeholder="Enter Manufacturer Name"
              fullWidth
              name="manufacturer"
              value={formData.manufacturer}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="p"
              style={{ fontWeight: "bold", color: "#444", display: "block" }}
            >
              Access Credentials
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <label style={{ marginLeft: "0.2rem", color: "#555" }}>
              Camera Access
            </label>
            <FormControl fullWidth>
              <Select
                value={formData.cameraAccess}
                name="cameraAccess"
                onChange={handleChange}
              >
                <MenuItem value="Public">Public</MenuItem>
                <MenuItem value="Private">Private</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <label style={{ marginLeft: "0.2rem", color: "#555" }}>
              Detection Type
            </label>
            <FormControl fullWidth>
              <Select
                value={formData.detectionType}
                name="detectionType"
                onChange={handleChange}
              >
                {detectionTypes.map((t) => (
                  <MenuItem value={t}>{t}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={toggleInputMode}>
              {inputMode === "details"
                ? "Have an RTSP URL instead?"
                : "Enter details instead"}{" "}
            </Button>
          </Grid>

          {inputMode == "details" ? (
            <>
              <Grid item xs={6}>
                <label style={{ marginLeft: "0.2rem", color: "#555" }}>
                  IP Address
                </label>
                <TextField
                  style={{ marginTop: "0.3rem" }}
                  variant="outlined"
                  placeholder="Enter IP Address"
                  fullWidth
                  name="ipAddress"
                  value={formData.ipAddress}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <label style={{ marginLeft: "0.2rem", color: "#555" }}>
                  RTSP Port
                </label>
                <TextField
                  style={{ marginTop: "0.3rem" }}
                  variant="outlined"
                  placeholder="Enter RTSP Port"
                  fullWidth
                  name="port"
                  value={formData.port}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <label style={{ marginLeft: "0.2rem", color: "#555" }}>
                  NVR Username
                </label>
                <TextField
                  style={{ marginTop: "0.3rem" }}
                  variant="outlined"
                  placeholder="Enter NVR Username"
                  fullWidth
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <label style={{ marginLeft: "0.2rem", color: "#555" }}>
                  Password
                </label>
                <TextField
                  type="password"
                  style={{ marginTop: "0.3rem" }}
                  variant="outlined"
                  placeholder="Enter Password"
                  fullWidth
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <label style={{ marginLeft: "0.2rem", color: "#555" }}>
                  Channel Id
                </label>
                <TextField
                  style={{ marginTop: "0.3rem" }}
                  variant="outlined"
                  placeholder="Enter Channel Id"
                  fullWidth
                  name="channel"
                  value={formData.channel}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <label style={{ marginLeft: "0.2rem", color: "#555" }}>
                  Input Type
                </label>
                <FormControl fullWidth>
                  <Select
                    value={formData.inputType}
                    name="inputType"
                    onChange={handleChange}
                  >
                    {inputTypes.map((t) => (
                      <MenuItem value={t}>{t}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <label style={{ color: "#555" }}>RTSP URL</label>
              <TextField
                style={{ marginTop: "0.3rem" }}
                variant="outlined"
                placeholder="Enter the RTSP URL"
                fullWidth
                name="rtspUrl"
                value={formData.rtspUrl || ""}
                onChange={handleChange}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography
              variant="p"
              style={{ fontWeight: "bold", color: "#444" }}
            >
              Add To Location
            </Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <label style={{ marginLeft: '0.2rem', color: '#555' }}>Region & City</label>
            <TextField style={{ marginTop: '0.3rem' }} variant='outlined' placeholder='Enter Region & City' fullWidth />
          </Grid> */}
          <Grid item xs={12}>
            <label style={{ marginLeft: "0.2rem", color: "#555" }}>
              Location
            </label>
            <Autocomplete
              options={locations}
              getOptionLabel={(option) => option?.name || ""}
              value={location}
              onChange={(_, newVal) => {
                setLocation(newVal)
              }}
              onInputChange={(e, newInputValue) => {
                handleLocationChange(newInputValue)
              }}
              sx={{ width: "100%", pt: "5px" }}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  label="Search location"
                />
              )}
              label="Search location"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1.3rem 1.5rem",
        }}
      >
        <Button onClick={close} size="large">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={(e) => handleSubmit(e)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddCamera
