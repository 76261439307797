import { Grid, Stack } from "@mui/material"
import React, { useContext } from "react"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import DashboardIcon from "../assets_icons/DashboardIcon.svg"
import LiveViewIcon from "../assets_icons/LiveViewIcon.svg"
import ManagementIcon from "../assets_icons/ManagementIcon.svg"
import TicketingIcon from "../assets_icons/TicketingIcon.svg"
import { Link } from "react-router-dom"
import UserContext from "../contexts/user-context"

export const menuItems = [
  {
    name: "Dashboard",
    link: "/dashboard",
    Icon: <img src={DashboardIcon} alt="dashboard icon" />,
    subheader: "Overview of Key Metrics",
    description: "Get a quick snapshot of important data and insights with our dashboard feature",
  },
  {
    name: "Live view",
    link: "/camera",
    Icon: <img src={LiveViewIcon} alt="live view icon" />,
    subheader: "Real-Time Monitoring",
    description: "Keep an eye on your operations with our live view feature",
  },
  {
    name: "Ticketing",
    link: "/tickets",
    Icon: <img src={TicketingIcon} alt="ticketing icon" />,
    subheader: "Effective Issue Tracking",
    description: "Keep track of customer inquiries and issues with our ticketing system",
  },
  {
    name: "Management",
    link: "/management",
    Icon: <img src={ManagementIcon} alt="management icon" />,
    subheader: "Centralized Control",
    description: "Streamline your operations with our management tools",
  },
]

const Menu = ({ setSelectedPage }) => {
  const { user } = useContext(UserContext)
  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h6" sx={{ flexGrow: "1", pt: 7 }}>
        Welcome{" "}
        <Typography display="inline" color="primary" variant="inherit">
          {user.name}
        </Typography>
      </Typography>

      <Grid container mt={10} spacing={3} sx={{ width: "60%" }}>
        {menuItems.map((menuItem) => (
          <MenuItem
            {...menuItem}
            changePage={() => setSelectedPage(menuItem)}
          />
        ))}
      </Grid>
    </Stack>
  )
}

const MenuItem = ({ name, link, Icon, changePage, subheader, description }) => {
  return (
    <Grid item xs={6}>
      <Link to={link} onClick={changePage}>
        <Card
          elevation={0}
          sx={{
            "&:hover": {
              transform: "translateY(-5px)",
              transition: "transform 0.3s, boxShadow 0.3s",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            },
          }}
        >
          <CardHeader
            avatar={Icon}
            title={
              <Typography variant="h6" color="primary">
                {name}
              </Typography>
            }
            subheader={subheader}
          />

          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  )
}

export default Menu
