import { useState } from "react"
import { Snackbar, Alert } from "@mui/material"

export const withToast = (WrappedComponent) => {
  return (props) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("I'm a custom snackbar")
    const [severity, setSeverity] = useState("error")

    const toast = (message, severity = "error") => {
      setMessage(message)
      setSeverity(severity)
      setOpen(true)
    }

    const closeToast = (_, reason) => reason !== "clickaway" && setOpen(false)

    return (
      <>
        <WrappedComponent {...props} toast={toast} />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={5000}
          open={open}
          onClose={closeToast}
        >
          <Alert variant="filled" onClose={closeToast} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </>
    )
  }
}
