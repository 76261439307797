import { useState, useEffect } from "react"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import { addDays } from "date-fns"
import { DateRangePicker } from "react-date-range"
import { useDispatch, useSelector } from "react-redux"
import { addSelectedDate, updateSelectedDate } from "../redux/presentDateSlicer"
import { Button } from "@mui/material"
import { addDateRange, updateDateRange } from "../redux/dateSlicers"
import { addCardDate } from "../redux/cardDate"
import { decrement } from "../redux/Ids"
import { addAllDate } from "../redux/allDates"
import {
  formatToTwoDigits,
  getAllHoursInRange,
  getAllWeekDaysInRange,
  getAllWeeksInRange,
  getAllMonthsInRange,
  getAllDaysInRange,
} from "../utilities/functions"
import theme from "../style/theme"

const months = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

const Calendar2 = ({ id, handleClose, type }) => {
  const dispatch = useDispatch()

  const ids = useSelector((state) => state.Ids)
  const comparisonDates = useSelector((state) => state.dateRange)
  const dates = useSelector((state) => state.dateRange)

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  })

  const [item, setItem] = useState({
    from: new Date(),
    to: new Date(),
  })

  const changeDate = () => {
    console.log(id, item)
    let startDate = item.from
    let endDate = item.to
    comparisonDates.map((d) => {
      if (d.id === id) return
      if (new Date(d.from) < new Date(startDate)) {
        startDate = d.from
      }
      if (new Date(d.to) > new Date(endDate)) {
        endDate = d.to
      }
    })
    startDate = new Date(startDate)
    endDate = new Date(endDate)
    let startMonth = startDate.getMonth() + 1
    let endMonth = endDate.getMonth() + 1
    startMonth = formatToTwoDigits(startMonth.toString())
    endMonth = formatToTwoDigits(endMonth.toString())
    startDate = startDate.getDate()
    startDate = formatToTwoDigits(startDate.toString())
    endDate = endDate.getDate()
    endDate = formatToTwoDigits(endDate.toString())

    const allDates = getAllDaysInRange(
      new Date(`${item.from.getFullYear()}-${startMonth}-${startDate}`),
      new Date(`${item.to.getFullYear()}-${endMonth}-${endDate}`)
    )
    const allHours = getAllHoursInRange(item.from, item.to)
    const allWeekDays = getAllWeekDaysInRange(item.from, item.to)
    const allWeeks = getAllWeeksInRange(item.from, item.to)
    const allMonths = getAllMonthsInRange(item.from, item.to)

    // Only for current selection, other date slots are handled in Graph.js
    dispatch(
      addAllDate({
        hour: allHours,
        weekDay: allWeekDays,
        week: allWeeks,
        date: allDates,
        month: allMonths,
      })
    )

    let selectedStartYear = item.from.getFullYear()
    let selectedEndYear = item.to.getFullYear()
    let selectedStartMonth = formatToTwoDigits(
      (item.from.getMonth() + 1).toString()
    )
    let selectedEndMonth = formatToTwoDigits(
      (item.to.getMonth() + 1).toString()
    )
    let selectedStartDate = formatToTwoDigits(item.from.getDate().toString())
    let selectedEndDate = formatToTwoDigits(item.to.getDate().toString())

    if (type === "nav") {
      dispatch(
        updateSelectedDate({
          id: 1,
          from:
            selectedStartYear +
            "-" +
            selectedStartMonth +
            "-" +
            selectedStartDate,
          to: selectedEndYear + "-" + selectedEndMonth + "-" + selectedEndDate,
        })
      )

      dispatch(
        addCardDate({
          from:
            selectedStartYear +
            "-" +
            selectedStartMonth +
            "-" +
            selectedStartDate,
          to: selectedEndYear + "-" + selectedEndMonth + "-" + selectedEndDate,
        })
      )

      dispatch(
        updateDateRange({
          id: 1,
          from:
            months[item.from.getMonth() + 1] +
            " " +
            selectedStartDate +
            ", " +
            selectedStartYear,
          to:
            months[item.to.getMonth() + 1] +
            " " +
            selectedEndDate +
            ", " +
            selectedEndYear,
        })
      )
    } else if (!dates.some((item) => item.id === id) && type !== "nav") {
      dispatch(
        addDateRange({
          id: ids.value,
          from:
            months[item.from.getMonth() + 1] +
            " " +
            selectedStartDate +
            ", " +
            selectedStartYear,
          to:
            months[item.to.getMonth() + 1] +
            " " +
            selectedEndDate +
            ", " +
            selectedEndYear,
        })
      )
      dispatch(
        addSelectedDate({
          id: ids.value,
          from:
            selectedStartYear +
            "-" +
            selectedStartMonth +
            "-" +
            selectedStartDate,
          to: selectedEndYear + "-" + selectedEndMonth + "-" + selectedEndDate,
        })
      )
    } else {
      if (id === 1) {
        dispatch(
          addCardDate({
            from:
              selectedStartYear +
              "-" +
              selectedStartMonth +
              "-" +
              selectedStartDate,
            to:
              selectedEndYear + "-" + selectedEndMonth + "-" + selectedEndDate,
          })
        )
      }
      dispatch(
        updateSelectedDate({
          id: id,
          from:
            selectedStartYear +
            "-" +
            selectedStartMonth +
            "-" +
            selectedStartDate,
          to: selectedEndYear + "-" + selectedEndMonth + "-" + selectedEndDate,
        })
      )

      dispatch(
        updateDateRange({
          id: id,
          from:
            months[item.from.getMonth() + 1] +
            " " +
            selectedStartDate +
            ", " +
            selectedStartYear,
          to:
            months[item.to.getMonth() + 1] +
            " " +
            selectedEndDate +
            ", " +
            selectedEndYear,
        })
      )
    }

    handleClose()
  }

  const close = () => {
    handleClose()
    dispatch(decrement())
  }

  return (
    <div>
      <DateRangePicker
        onChange={(item) => {
          setState({ ...item })
          setItem({
            from: item.selection.startDate,
            to: item.selection.endDate,
          })
        }}
        months={1}
        minDate={addDays(new Date(), -300)}
        maxDate={addDays(new Date(), 900)}
        direction="vertical"
        scroll={{ enabled: true }}
        ranges={[state.selection]}
        rangeColors={[theme.palette.primary.main]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5rem 1rem",
        }}
      >
        <Button onClick={close}>Cancel</Button>
        <Button onClick={changeDate} variant="contained">
          Save
        </Button>
      </div>
    </div>
  )
}

export default Calendar2
