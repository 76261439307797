import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Container,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Toggle } from "../components/styled"
import ReactMap from "../components/ReactMap"
import { getAllTickets } from "../api/new-project"
import AcknowledgeModal from "../components/AcknowledgeModal"
import LoadingContent from "../components/LoadingContent"

const TicketCard = ({
  id,
  detectionType,
  address,
  image,
  status,
  expectedResolution,
  actualResolution,
  xs,
  action,
}) => (
  <Grid item xs={xs || 4}>
    <Card
      elevation={0}
      sx={{
        "&:hover": {
          transform: "translateY(-5px)",
          transition: "transform 0.3s, boxShadow 0.3s",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
      }}
    >
      <CardHeader
        avatar={
          <img
            src={image}
            alt="a"
            style={{ height: "4rem", borderRadius: "0.5rem" }}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: 20 }}>
            {detectionType}
          </Typography>
        }
        subheader={
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontWeight: 600 }}
            >
              {address}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {status === "Reviewed" || status === "Resolved"
                ? "Resolved on " +
                  new Date(actualResolution).toLocaleDateString()
                : status === "Assigned"
                ? "Resolved on " +
                  new Date(expectedResolution).toLocaleDateString()
                : "Not Assigned yet"}
            </Typography>
          </>
        }
      />

      <CardActions sx={{ gap: 2 }}>
        <Chip label={status} color="error" variant="outlined" />
        <Link to={"/tickets/" + id} style={{ width: "100%" }}>
          <Button size="small" variant="contained" fullWidth>
            View
          </Button>
        </Link>
        {/* <Button onClick={action} variant="contained" fullWidth>
          Acknowledge
        </Button> */}
      </CardActions>
    </Card>
  </Grid>
)

const Tickets = () => {
  const [showModal, setShowModal] = useState(false)
  const [view, setView] = useState(0)
  const [tickets, setTickets] = useState([])
  const [selectedTicket, setSelectedTicket] = useState({})
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")

  const fetchTickets = () => {
    setLoading(true)
    getAllTickets({
      // filter: {
      //   detectionType: search,
      // },
    })
      .then((res) => setTickets(res.data || []))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchTickets()
  }, [search])

  return (
    <Container maxWidth="lg" fullWidth sx={{ pt: 5 }}>
      <Stack alignItems="center" mb={3}>
        <Toggle
          val1={0}
          val2={1}
          label1="Map View"
          label2="List View"
          selected={view}
          setSelected={setView}
        />
      </Stack>
      {view ? (
        <LoadingContent loading={loading}>
          <Grid container spacing={3}>
            {tickets.map((ticket) => (
              <TicketCard
                {...ticket}
                action={() => {
                  setSelectedTicket(ticket)
                  setShowModal(true)
                }}
              />
            ))}
          </Grid>
          <Stack alignItems={"center"} sx={{ py: 2 }}>
            <Pagination count={1} />
          </Stack>
        </LoadingContent>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <ReactMap markers={tickets} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
              fullWidth
              sx={{ mb: 2 }}
            />
            <Grid
              container
              spacing={2}
              sx={{ height: "55vh", overflow: "auto" }}
            >
              {tickets.map((ticket) => (
                <TicketCard
                  {...ticket}
                  action={() => {
                    setSelectedTicket(ticket)
                    setShowModal(true)
                  }}
                  xs={12}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <AcknowledgeModal
        open={showModal}
        close={() => setShowModal(false)}
        ticket={selectedTicket}
        refresh={fetchTickets}
      />
    </Container>
  )
}

export default Tickets
