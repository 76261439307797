import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  useTheme,
} from "@mui/material"
// import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from "react"
import { LockIcon } from "../assets_icons/Icons"
import LockRoundedIcon from "@mui/icons-material/LockRounded"

export const PasswordInput = ({ style, ...rest }) => {
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  // const [password, setPassword] = useState("");
  // const handleChange = (e) => {
  //   setPassword(e.target.value);
  // };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    //event.preventDefault();
  }

  const iconProps = {
    fontSize: "large",
    sx: {
      color: "#449A68",
    },
  }

  return (
    <>
      <TextField
        {...rest}
        variant='outlined'
        sx={{
          my: '0.2rem',
          '& .MuiOutlinedInput-root': {
            input: {
              padding: '1.1rem',
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <LockRoundedIcon {...iconProps} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {/* {showPassword ? (
                  <VisibilityOff style={{ color: theme.palette.secondary.light }} />
                ) : (
                  <Visibility style={{ color: theme.palette.secondary.light }} />
                )} */}
              </IconButton>
            </InputAdornment>
          ),
        }}
        type={showPassword ? 'text' : 'password'}
        placeholder='Password'
        style={{ ...style }}
      />
    </>
  );
}
