import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import AddLocation from '../../layouts/add-location';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';

export default function StepThree({ snackbarState, snackbarOpen, snackbarClose, snackbarAction }) {
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [showAddLocation, setShowAddLocation] = React.useState(false);
  const [error, setError] = React.useState('');

  const editRow = (row) => {};

  const deleteRow = (selectedRow) => {
    console.log(selectedRow);
    setRows((rows) => rows.filter((r) => r.name !== selectedRow.name));
  };

  const submitLocations = () => {
    console.log(rows);
    navigate('/');
  };

  return (
    <div>
      <AddLocation open={showAddLocation} onClose={() => setShowAddLocation(false)} setLocations={setRows} />
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={snackbarClose} message={error} action={snackbarAction} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Code</TableCell>
              {/* <TableCell></TableCell> */}
              {/* <TableCell></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  {row.name}
                </TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>
                  {/* <button onClick={(e) => editRow(row)}>Edit</button> */}
                  {/* <button onClick={(e) => deleteRow(row)}>Delete</button> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button style={{ marginTop: '1rem', width: '100%' }} onClick={() => setShowAddLocation(true)}>
        Add Location
      </Button>
      <Button color='primary' variant='contained' style={{ marginTop: '1rem', width: '100%' }} onClick={() => submitLocations()}>
        Continue
      </Button>
    </div>
  );
}
