import { useContext, useEffect, useState } from "react"
import {
  AppBar,
  Toolbar,
  Typography,
  Badge,
  Checkbox,
  FormControlLabel,
  IconButton,
  Button,
  Box,
} from "@mui/material"
import Optika from "../assets_icons/optika.png"
import Top_left from "../assets_icons/top_left.svg"
import Notification from "../assets_icons/Notification.svg"
import Line from "../assets_icons/navBarLine.svg"
import DateSelect from "./dateSelect"
import styles from "../style/styleNavbar"
import { getAllStores } from "../api"
import { addLocations, toggleSelectedLocation } from "../redux/stores"
import { useDispatch, useSelector } from "react-redux"
import OutsideClickChecker from "../utilities/outside-click-checker"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Toggle } from "./styled"
import { makeStyles } from "@mui/styles"
import { useQuery } from "react-query"
import UserContext from "../contexts/user-context"
import { Auth } from "aws-amplify"
import AddRoundedIcon from "@mui/icons-material/AddRounded"

const useStyles = makeStyles(styles)

function Navbar({ selectedPage }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { user, setUser } = useContext(UserContext)

  const storesData = useSelector((state) => state.stores)
  const [displayFilters, setDisplayFilters] = useState(false)
  const [pageToggle, setPageToggle] = useState(0)

  useQuery("getAllStores", () => getAllStores(user.sub || storesData.name), {
    onSuccess: ({ data }) => {
      if (!data?.length) data = []
      const obj = {
        name: storesData.name,
        locations: data,
      }
      dispatch(addLocations(obj))
      if (!data.length) navigate("/locations")
    },
  })

  useEffect(() => {
    if (location.pathname === selectedPage.link) {
      setPageToggle(0)
    } else if (location.pathname === "/menu") {
      setPageToggle(1)
    }
  }, [location.pathname, selectedPage])

  const handleSignout = () => {
    Auth.signOut().then(() => {
      setUser(null)
      navigate("/")
      window.location.reload()
    })
  }
  const toggleDisplayFilters = () => {
    setDisplayFilters((d) => !d)
  }

  const handleFilterSelect = (data) => {
    dispatch(toggleSelectedLocation(data))
  }

  return location.pathname === "/locations" ? (
    ""
  ) : (
    <div>
      <AppBar position="static" color="transparent" className={classes.nav}>
        <Toolbar>
          {location.pathname === "/menu" ? (
            <>
              <img
                style={{ height: "2.25rem" }}
                src={Optika}
                alt="optika icon"
              />
              <Box sx={{ flexGrow: "1" }} />
            </>
          ) : (
            <>
              <img
                style={{ cursor: "pointer" }}
                src={Top_left}
                alt="filter icon"
                onClick={() => toggleDisplayFilters()}
              />
              {displayFilters && (
                <OutsideClickChecker
                  onClickOutside={() => setDisplayFilters(false)}
                >
                  <div
                    className={classes.filtersBox}
                    style={{ height: "fit-content" }}
                  >
                    {(!storesData.locations ||
                      storesData.locations.length === 0) &&
                      "No stores found"}
                    {storesData.locations.map((data) => (
                      <label htmlFor={data.id} className={classes.filtersItem}>
                        <FormControlLabel
                          style={{
                            width: "100%",
                          }}
                          control={
                            <Checkbox
                              checked={Boolean(
                                storesData.selectedLocations.find(
                                  (x) => x.id === data.id
                                )
                              )}
                              onChange={() => handleFilterSelect(data)}
                              color="primary"
                            />
                          }
                          label={data.name}
                        />
                      </label>
                    ))}
                    <Link to="/locations">
                      <Button
                        onClick={() => setDisplayFilters(false)}
                        fullWidth
                        startIcon={<AddRoundedIcon />}
                      >
                        Add Location
                      </Button>
                    </Link>
                  </div>
                </OutsideClickChecker>
              )}
              <Typography
                onClick={() => {
                  navigate("/")
                  setPageToggle(0)
                }}
                sx={{
                  cursor: "pointer",
                }}
                className={classes.heading}
                variant="h6"
                color="primary"
              >
                {user.name || storesData.name}
              </Typography>

              <img src={Line} alt="filter icon" />
              <Typography
                className={classes.subHeading}
                variant="h6"
                color="inherit"
              >
                {storesData.selectedLocations[0]?.name ||
                  "No Locations Selected"}
                {storesData.selectedLocations.length > 1 && (
                  <Badge
                    badgeContent={` +${
                      storesData.selectedLocations.length - 1
                    }`}
                    color="primary"
                    style={{
                      borderRadius: "999px",
                      margin: "auto auto 1rem 1rem",
                    }}
                  />
                )}
              </Typography>
            </>
          )}

          {location.pathname === "/dashboard" && <DateSelect />}

          <IconButton sx={{ mx: 1 }} color="primary">
          <img
                style={{ cursor: "pointer" }}
                src={Notification}
                alt="notification icon"
              />
          </IconButton>
          <Toggle
            val1={1}
            val2={0}
            label1="Menu"
            label2={selectedPage.name}
            selected={pageToggle}
            setSelected={setPageToggle}
            link1={"/menu"}
            link2={selectedPage.link}
          />
          <Button sx={{ mx: 1 }} onClick={() => handleSignout()}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navbar
